import {SelectItem} from "../../data-types";

export const CountryOptions: SelectItem[] = [
    {label: "Not Specified", value: "Not Specified"},
    {label: "United States of America", value: "US"},
    {label: "Canada", value: "CA"},
    {label: "Mexico", value: "MX"},
    {label: "Afghanistan", value: "AF"},
    {label: "Ãland Islands", value: "AX"},
    {label: "Albania", value: "AL"},
    {label: "Algeria", value: "DZ"},
    {label: "American Samoa", value: "AS"},
    {label: "Andorra", value: "AD"},
    {label: "Angola", value: "AO"},
    {label: "Anguilla", value: "AI"},
    {label: "Antarctica", value: "AQ"},
    {label: "Antigua and Barbuda", value: "AG"},
    {label: "Argentina", value: "AR"},
    {label: "Armenia", value: "AM"},
    {label: "Aruba", value: "AW"},
    {label: "Australia", value: "AU"},
    {label: "Austria", value: "AT"},
    {label: "Azerbaijan", value: "AZ"},
    {label: "Bahamas", value: "BS"},
    {label: "Bahrain", value: "BH"},
    {label: "Bangladesh", value: "BD"},
    {label: "Barbados", value: "BB"},
    {label: "Belarus", value: "BY"},
    {label: "Belgium", value: "BE"},
    {label: "Belize", value: "BZ"},
    {label: "Benin", value: "BJ"},
    {label: "Bermuda", value: "BM"},
    {label: "Bhutan", value: "BT"},
    {label: "Bolivia", value: "BO"},
    {label: "Bonaire, Sint Eustatius and Saba", value: "BQ"},
    {label: "Bosnia and Herzegovina", value: "BA"},
    {label: "Botswana", value: "BW"},
    {label: "Bouvet Island", value: "BV"},
    {label: "Brazil", value: "BR"},
    {label: "British Indian Ocean Territory", value: "IO"},
    {label: "Brunei Darussalam", value: "BN"},
    {label: "Bulgaria", value: "BG"},
    {label: "Burkina Faso", value: "BF"},
    {label: "Burundi", value: "BI"},
    {label: "Côte d'Ivoire", value: "CI"},
    {label: "Cabo Verde", value: "CV"},
    {label: "Cambodia", value: "KH"},
    {label: "Cameroon", value: "CM"},
    {label: "Cayman Islands", value: "KY"},
    {label: "Central African Republic", value: "CF"},
    {label: "Chad", value: "TD"},
    {label: "Chile", value: "CL"},
    {label: "China", value: "CN"},
    {label: "Christmas Island", value: "CX"},
    {label: "Cocos (Keeling) Islands", value: "CC"},
    {label: "Colombia", value: "CO"},
    {label: "Comoros", value: "KM"},
    {label: "Congo", value: "CG"},
    {label: "Cook Islands", value: "CK"},
    {label: "Costa Rica", value: "CR"},
    {label: "Croatia", value: "HR"},
    {label: "Cuba", value: "CU"},
    {label: "Curaçao", value: "CW"},
    {label: "Cyprus", value: "CY"},
    {label: "Czechia", value: "CZ"},
    {label: "Democratic Republic of the Congo", value: "CD"},
    {label: "Denmark", value: "DK"},
    {label: "Djibouti", value: "DJ"},
    {label: "Dominica", value: "DM"},
    {label: "Dominican Republic", value: "DO"},
    {label: "Ecuador", value: "EC"},
    {label: "Egypt", value: "EG"},
    {label: "El Salvador", value: "SV"},
    {label: "Equatorial Guinea", value: "GQ"},
    {label: "Eritrea", value: "ER"},
    {label: "Estonia", value: "EE"},
    {label: "Eswatini", value: "SZ"},
    {label: "Ethiopia", value: "ET"},
    {label: "Falkland Islands (Malvinas)", value: "FK"},
    {label: "Faroe Islands", value: "FO"},
    {label: "Fiji", value: "FJ"},
    {label: "Finland", value: "FI"},
    {label: "France", value: "FR"},
    {label: "French Guiana", value: "GF"},
    {label: "French Polynesia", value: "PF"},
    {label: "French Southern Territories", value: "TF"},
    {label: "Gabon", value: "GA"},
    {label: "Gambia", value: "GM"},
    {label: "Georgia", value: "GE"},
    {label: "Germany", value: "DE"},
    {label: "Ghana", value: "GH"},
    {label: "Gibraltar", value: "GI"},
    {label: "Greece", value: "GR"},
    {label: "Greenland", value: "GL"},
    {label: "Grenada", value: "GD"},
    {label: "Guadeloupe", value: "GP"},
    {label: "Guam", value: "GU"},
    {label: "Guatemala", value: "GT"},
    {label: "Guernsey", value: "GG"},
    {label: "Guinea", value: "GN"},
    {label: "Guinea-Bissau", value: "GW"},
    {label: "Guyana", value: "GY"},
    {label: "Haiti", value: "HT"},
    {label: "Heard Island and McDonald Islands", value: "HM"},
    {label: "Holy See", value: "VA"},
    {label: "Honduras", value: "HN"},
    {label: "Hong Kong", value: "HK"},
    {label: "Hungary", value: "HU"},
    {label: "Iceland", value: "IS"},
    {label: "India", value: "IN"},
    {label: "Indonesia", value: "ID"},
    {label: "Iran", value: "IR"},
    {label: "Iraq", value: "IQ"},
    {label: "Ireland", value: "IE"},
    {label: "Isle of Man", value: "IM"},
    {label: "Israel", value: "IL"},
    {label: "Italy", value: "IT"},
    {label: "Jamaica", value: "JM"},
    {label: "Japan", value: "JP"},
    {label: "Jersey", value: "JE"},
    {label: "Jordan", value: "JO"},
    {label: "Kazakhstan", value: "KZ"},
    {label: "Kenya", value: "KE"},
    {label: "Kiribati", value: "KI"},
    {label: "Kuwait", value: "KW"},
    {label: "Kyrgyzstan", value: "KG"},
    {label: "Lao People's Democratic Republic", value: "LA"},
    {label: "Latvia", value: "LV"},
    {label: "Lebanon", value: "LB"},
    {label: "Lesotho", value: "LS"},
    {label: "Liberia", value: "LR"},
    {label: "Libya", value: "LY"},
    {label: "Liechtenstein", value: "LI"},
    {label: "Lithuania", value: "LT"},
    {label: "Luxembourg", value: "LU"},
    {label: "Macao", value: "MO"},
    {label: "Madagascar", value: "MG"},
    {label: "Malawi", value: "MW"},
    {label: "Malaysia", value: "MY"},
    {label: "Maldives", value: "MV"},
    {label: "Mali", value: "ML"},
    {label: "Malta", value: "MT"},
    {label: "Marshall Islands", value: "MH"},
    {label: "Martinique", value: "MQ"},
    {label: "Mauritania", value: "MR"},
    {label: "Mauritius", value: "MU"},
    {label: "Mayotte", value: "YT"},
    {label: "Micronesia", value: "FM"},
    {label: "Moldova", value: "MD"},
    {label: "Monaco", value: "MC"},
    {label: "Mongolia", value: "MN"},
    {label: "Montenegro", value: "ME"},
    {label: "Montserrat", value: "MS"},
    {label: "Morocco", value: "MA"},
    {label: "Mozambique", value: "MZ"},
    {label: "Myanmar", value: "MM"},
    {label: "Namibia", value: "NA"},
    {label: "Nauru", value: "NR"},
    {label: "Nepal", value: "NP"},
    {label: "Netherlands", value: "NL"},
    {label: "New Caledonia", value: "NC"},
    {label: "New Zealand", value: "NZ"},
    {label: "Nicaragua", value: "NI"},
    {label: "Niger", value: "NE"},
    {label: "Nigeria", value: "NG"},
    {label: "Niue", value: "NU"},
    {label: "Norfolk Island", value: "NF"},
    {label: "North Korea", value: "KP"},
    {label: "North Macedonia", value: "MK"},
    {label: "Northern Mariana Islands", value: "MP"},
    {label: "Norway", value: "NO"},
    {label: "Oman", value: "OM"},
    {label: "Pakistan", value: "PK"},
    {label: "Palau", value: "PW"},
    {label: "Palestine", value: "PS"},
    {label: "Panama", value: "PA"},
    {label: "Papua New Guinea", value: "PG"},
    {label: "Paraguay", value: "PY"},
    {label: "Peru", value: "PE"},
    {label: "Philippines", value: "PH"},
    {label: "Pitcairn", value: "PN"},
    {label: "Poland", value: "PL"},
    {label: "Portugal", value: "PT"},
    {label: "Puerto Rico", value: "PR"},
    {label: "Qatar", value: "QA"},
    {label: "Reunion", value: "RE"},
    {label: "Romania", value: "RO"},
    {label: "Russian Federation", value: "RU"},
    {label: "Rwanda", value: "RW"},
    {label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH"},
    {label: "Saint Kitts and Nevis", value: "KN"},
    {label: "Saint Lucia", value: "LC"},
    {label: "Saint Martin (French part)", value: "MF"},
    {label: "Saint Pierre and Miquelon", value: "PM"},
    {label: "Saint Vincent and the Grenadines", value: "VC"},
    {label: "Samoa", value: "WS"},
    {label: "San Marino", value: "SM"},
    {label: "Sao Tome and Principe", value: "ST"},
    {label: "Saudi Arabia", value: "SA"},
    {label: "Senegal", value: "SN"},
    {label: "Serbia", value: "RS"},
    {label: "Seychelles", value: "SC"},
    {label: "Sierra Leone", value: "SL"},
    {label: "Singapore", value: "SG"},
    {label: "Sint Maarten (Dutch part)", value: "SX"},
    {label: "Slovakia", value: "SK"},
    {label: "Slovenia", value: "SI"},
    {label: "Solomon Islands", value: "SB"},
    {label: "Somalia", value: "SO"},
    {label: "South Africa", value: "ZA"},
    {label: "South Georgia and the South Sandwich Islands", value: "GS"},
    {label: "South Korea", value: "KR"},
    {label: "South Sudan", value: "SS"},
    {label: "Spain", value: "ES"},
    {label: "Sri Lanka", value: "LK"},
    {label: "Sudan", value: "SD"},
    {label: "Suriname", value: "SR"},
    {label: "Svalbard and Jan Mayen", value: "SJ"},
    {label: "Sweden", value: "SE"},
    {label: "Switzerland", value: "CH"},
    {label: "Syrian Arab Republic", value: "SY"},
    {label: "Taiwan", value: "TW"},
    {label: "Tajikistan", value: "TJ"},
    {label: "Tanzania, United Republic of", value: "TZ"},
    {label: "Thailand", value: "TH"},
    {label: "Timor-Leste", value: "TL"},
    {label: "Togo", value: "TG"},
    {label: "Tokelau", value: "TK"},
    {label: "Tonga", value: "TO"},
    {label: "Trinidad and Tobago", value: "TT"},
    {label: "Tunisia", value: "TN"},
    {label: "Turkey", value: "TR"},
    {label: "Turkmenistan", value: "TM"},
    {label: "Turks and Caicos Islands", value: "TC"},
    {label: "Tuvalu", value: "TV"},
    {label: "Uganda", value: "UG"},
    {label: "Ukraine", value: "UA"},
    {label: "United Arab Emirates", value: "AE"},
    {label: "United Kingdom", value: "GB"},
    {label: "United States Minor Outlying Islands", value: "UM"},
    {label: "Uruguay", value: "UY"},
    {label: "Uzbekistan", value: "UZ"},
    {label: "Vanuatu", value: "VU"},
    {label: "Venezuela", value: "VE"},
    {label: "Viet Nam", value: "VN"},
    {label: "Virgin Islands (British)", value: "VG"},
    {label: "Virgin Islands (U.S.)", value: "VI"},
    {label: "Wallis and Futuna", value: "WF"},
    {label: "Western Sahara", value: "EH"},
    {label: "Yemen", value: "YE"},
    {label: "Zambia", value: "ZM"},
    {label: "Zimbabwe", value: "ZW"}
]

export const LanguageOptions: SelectItem[] = [
        // { label: 'Not Specified', value: 'Not Specified' },
        { label: 'English', value: 'ENG' },
        { label: 'Spanish', value: 'SPA' },
        { label: 'Abkhazian', value: 'ABK' },
        { label: 'Afar', value: 'AAR' },
        { label: 'Afrikaans', value: 'AFR' },
        { label: 'Akan', value: 'AKA' },
        { label: 'Albanian', value: 'SQI' },
        { label: 'Amharic', value: 'AMH' },
        { label: 'Arabic', value: 'ARA' },
        { label: 'Aragonese', value: 'ARG' },
        { label: 'Armenian', value: 'HYE' },
        { label: 'Assamese', value: 'ASM' },
        { label: 'Avaric', value: 'AVA' },
        { label: 'Avestan', value: 'AVE' },
        { label: 'Aymara', value: 'AYM' },
        { label: 'Azerbaijani', value: 'AZE' },
        { label: 'Bambara', value: 'BAM' },
        { label: 'Bashkir', value: 'BAK' },
        { label: 'Basque', value: 'EUS' },
        { label: 'Belarusian', value: 'BEL' },
        { label: 'Bengali', value: 'BEN' },
        { label: 'Bislama', value: 'BIS' },
        { label: 'Bosnian', value: 'BOS' },
        { label: 'Breton', value: 'BRE' },
        { label: 'Bulgarian', value: 'BUL' },
        { label: 'Burmese', value: 'MYA' },
        { label: 'Catalan, Valencian', value: 'CAT' },
        { label: 'Chamorro', value: 'CHA' },
        { label: 'Chechen', value: 'CHE' },
        { label: 'Chichewa, Chewa, Nyanja', value: 'NYA' },
        { label: 'Chinese', value: 'ZHO' },
        { label: 'Chuvash', value: 'CHV' },
        { label: 'Cornish', value: 'COR' },
        { label: 'Corsican', value: 'COS' },
        { label: 'Cree', value: 'CRE' },
        { label: 'Croatian', value: 'HRV' },
        { label: 'Czech', value: 'CES' },
        { label: 'Danish', value: 'DAN' },
        { label: 'Divehi, Dhivehi, Maldivian', value: 'DIV' },
        { label: 'Dutch, Flemish', value: 'NLD' },
        { label: 'Dzongkha', value: 'DZO' },
        { label: 'Esperanto', value: 'EPO' },
        { label: 'Estonian', value: 'EST' },
        { label: 'Ewe', value: 'EWE' },
        { label: 'Faroese', value: 'FAO' },
        { label: 'Fijian', value: 'FIJ' },
        { label: 'Finnish', value: 'FIN' },
        { label: 'French', value: 'FRA' },
        { label: 'Western Frisian', value: 'FRY' },
        { label: 'Fulah', value: 'FUL' },
        { label: 'Gaelic, Scottish Gaelic', value: 'GLA' },
        { label: 'Galician', value: 'GLG' },
        { label: 'Ganda', value: 'LUG' },
        { label: 'Georgian', value: 'KAT' },
        { label: 'German', value: 'DEU' },
        { label: 'Greek, Modern', value: 'ELL' },
        { label: 'Kalaallisut, Greenlandic', value: 'KAL' },
        { label: 'Guarani', value: 'GRN' },
        { label: 'Gujarati', value: 'GUJ' },
        { label: 'Haitian, Haitian Creole', value: 'HAT' },
        { label: 'Hausa', value: 'HAU' },
        { label: 'Hebrew', value: 'HEB' },
        { label: 'Herero', value: 'HER' },
        { label: 'Hindi', value: 'HIN' },
        { label: 'Hiri Motu', value: 'HMO' },
        { label: 'Hungarian', value: 'HUN' },
        { label: 'Icelandic', value: 'ISL' },
        { label: 'Ido', value: 'IDO' },
        { label: 'Igbo', value: 'IBO' },
        { label: 'Indonesian', value: 'IND' },
        { label: 'Interlingua', value: 'INA' },
        { label: 'Interlingue, Occidental', value: 'ILE' },
        { label: 'Inuktitut', value: 'IKU' },
        { label: 'Inupiaq', value: 'IPK' },
        { label: 'Irish', value: 'GLE' },
        { label: 'Italian', value: 'ITA' },
        { label: 'Japanese', value: 'JPN' },
        { label: 'Javanese', value: 'JAV' },
        { label: 'Kannada', value: 'KAN' },
        { label: 'Kanuri', value: 'KAU' },
        { label: 'Kashmiri', value: 'KAS' },
        { label: 'Kazakh', value: 'KAZ' },
        { label: 'Central Khmer', value: 'KHM' },
        { label: 'Kikuyu, Gikuyu', value: 'KIK' },
        { label: 'Kinyarwanda', value: 'KIN' },
        { label: 'Kirghiz, Kyrgyz', value: 'KIR' },
        { label: 'Komi', value: 'KOM' },
        { label: 'Kongo', value: 'KON' },
        { label: 'Korean', value: 'KOR' },
        { label: 'Kuanyama, Kwanyama', value: 'KUA' },
        { label: 'Kurdish', value: 'KUR' },
        { label: 'Lao', value: 'LAO' },
        { label: 'Latin', value: 'LAT' },
        { label: 'Latvian', value: 'LAV' },
        { label: 'Limburgan, Limburger, Limburgish', value: 'LIM' },
        { label: 'Lingala', value: 'LIN' },
        { label: 'Lithuanian', value: 'LIT' },
        { label: 'Luba-Katanga', value: 'LUB' },
        { label: 'Luxembourgish, Letzeburgesch', value: 'LTZ' },
        { label: 'Macedonian', value: 'MKD' },
        { label: 'Malagasy', value: 'MLG' },
        { label: 'Malay', value: 'MSA' },
        { label: 'Malayalam', value: 'MAL' },
        { label: 'Maltese', value: 'MLT' },
        { label: 'Manx', value: 'GLV' },
        { label: 'Maori', value: 'MRI' },
        { label: 'Marathi', value: 'MAR' },
        { label: 'Marshallese', value: 'MAH' },
        { label: 'Mongolian', value: 'MON' },
        { label: 'Nauru', value: 'NAU' },
        { label: 'Navajo, Navaho', value: 'NAV' },
        { label: 'North Ndebele', value: 'NDE' },
        { label: 'South Ndebele', value: 'NBL' },
        { label: 'Ndonga', value: 'NDO' },
        { label: 'Nepali', value: 'NEP' },
        { label: 'Norwegian', value: 'NOR' },
        { label: 'Norwegian Bokmål', value: 'NOB' },
        { label: 'Norwegian Nynorsk', value: 'NNO' },
        { label: 'Sichuan Yi, Nuosu', value: 'III' },
        { label: 'Occitan', value: 'OCI' },
        { label: 'Ojibwa', value: 'OJI' },
        { label: 'Oriya', value: 'ORI' },
        { label: 'Oromo', value: 'ORM' },
        { label: 'Ossetian, Ossetic', value: 'OSS' },
        { label: 'Pali', value: 'PIL' },
        { label: 'Pashto, Pushto', value: 'PUS' },
        { label: 'Persian', value: 'FAS' },
        { label: 'Polish', value: 'POL' },
        { label: 'Portuguese', value: 'POR' },
        { label: 'Punjabi, Panjabi', value: 'PAN' },
        { label: 'Quechua', value: 'QUE' },
        { label: 'Romanian, Moldovan', value: 'RON' },
        { label: 'Romansh', value: 'ROH' },
        { label: 'Rundi', value: 'RUN' },
        { label: 'Russian', value: 'RUS' },
        { label: 'Northern Sami', value: 'SME' },
        { label: 'Samoan', value: 'SMO' },
        { label: 'Sango', value: 'SAG' },
        { label: 'Sanskrit', value: 'SAN' },
        { label: 'Sardinian', value: 'SRD' },
        { label: 'Serbian', value: 'SRP' },
        { label: 'Shona', value: 'SNA' },
        { label: 'Sindhi', value: 'SND' },
        { label: 'Sinhala, Sinhalese', value: 'SIN' },
        { label: 'Slovak', value: 'SLK' },
        { label: 'Slovenian', value: 'SLV' },
        { label: 'Somali', value: 'SOM' },
        { label: 'Southern Sotho', value: 'SOT' },
        { label: 'Sundanese', value: 'SUN' },
        { label: 'Swahili', value: 'SWA' },
        { label: 'Swati', value: 'SSW' },
        { label: 'Swedish', value: 'SWE' },
        { label: 'Tagalog', value: 'TGL' },
        { label: 'Tahitian', value: 'TAH' },
        { label: 'Tajik', value: 'TGK' },
        { label: 'Tamil', value: 'TAM' },
        { label: 'Tatar', value: 'TAT' },
        { label: 'Telugu', value: 'TEL' },
        { label: 'Thai', value: 'THA' },
        { label: 'Tibetan', value: 'BOD' },
        { label: 'Tigrinya', value: 'TIR' },
        { label: 'Tonga (Tonga Islands)', value: 'TON' },
        { label: 'Tsonga', value: 'TSO' },
        { label: 'Tswana', value: 'TSN' },
        { label: 'Turkish', value: 'TUR' },
        { label: 'Turkmen', value: 'TUK' },
        { label: 'Twi', value: 'TWI' },
        { label: 'Uighur, Uyghur', value: 'UIG' },
        { label: 'Ukrainian', value: 'UKR' },
        { label: 'Urdu', value: 'URD' },
        { label: 'Uzbek', value: 'UZB' },
        { label: 'Venda', value: 'VEN' },
        { label: 'Vietnamese', value: 'VIE' },
        { label: 'Volapük', value: 'VOL' },
        { label: 'Walloon', value: 'WLN' },
        { label: 'Welsh', value: 'CYM' },
        { label: 'Wolof', value: 'WOL' },
        { label: 'Xhosa', value: 'XHO' },
        { label: 'Yiddish', value: 'YID' },
        { label: 'Yoruba', value: 'YOR' },
        { label: 'Zhuang, Chuang', value: 'ZHA' },
        { label: 'Zulu', value: 'ZUL' }
];

export const AgeOptions: SelectItem[] = [
    {value: -1, label: "Not Specified"},
    {value: 0, label: "Under 18"},
    {value: 1, label: "18 - 24"},
    {value: 2, label: "25 - 34"},
    {value: 3, label: "35 - 44"},
    {value: 4, label: "45 - 54"},
    {value: 5, label: "55 - 64"},
    {value: 6, label: "Over 64"}
];

export const EthnicityOptions: SelectItem[] = [
    {value: -1, label: "Not Specified"},
    {value: 0, label: "White"},
    {value: 1, label: "Black or African American"},
    {value: 2, label: "Asian"},
    {value: 3, label: "American Indian or Alaska Native"},
    {value: 4, label: "Native Hawaiian or Pacific Islander"},
    {value: 5, label: "Other"}
];

export const GenderOptions: SelectItem[] = [
    {value: -1, label: "Not Specified"},
    {value: 1, label: "Female"},
    {value: 0, label: "Male"},
    {value: 2, label: "Other"},
    {value: 3, label: "Prefer not to disclose"}
];

export const StateOptions: SelectItem[] = [
    {label: "Not Specified", value: "Not Specified"},
    {value: "AL", label: "AL"},
    {value: "AK", label: "AK"},
    {value: "AZ", label: "AZ"},
    {value: "AR", label: "AR"},
    {value: "CA", label: "CA"},
    {value: "CO", label: "CO"},
    {value: "CT", label: "CT"},
    {value: "DE", label: "DE"},
    {value: "DC", label: "DC"},
    {value: "FL", label: "FL"},
    {value: "GA", label: "GA"},
    {value: "HI", label: "HI"},
    {value: "ID", label: "ID"},
    {value: "IL", label: "IL"},
    {value: "IN", label: "IN"},
    {value: "IA", label: "IA"},
    {value: "KS", label: "KS"},
    {value: "KY", label: "KY"},
    {value: "LA", label: "LA"},
    {value: "ME", label: "ME"},
    {value: "MD", label: "MD"},
    {value: "MA", label: "MA"},
    {value: "MI", label: "MI"},
    {value: "MN", label: "MN"},
    {value: "MS", label: "MS"},
    {value: "MO", label: "MO"},
    {value: "MT", label: "MT"},
    {value: "NE", label: "NE"},
    {value: "NV", label: "NV"},
    {value: "NH", label: "NH"},
    {value: "NJ", label: "NJ"},
    {value: "NM", label: "NM"},
    {value: "NY", label: "NY"},
    {value: "NC", label: "NC"},
    {value: "ND", label: "ND"},
    {value: "OH", label: "OH"},
    {value: "OK", label: "OK"},
    {value: "OR", label: "OR"},
    {value: "PA", label: "PA"},
    {value: "RI", label: "RI"},
    {value: "SC", label: "SC"},
    {value: "SD", label: "SD"},
    {value: "TN", label: "TN"},
    {value: "TX", label: "TX"},
    {value: "UT", label: "UT"},
    {value: "VT", label: "VT"},
    {value: "VA", label: "VA"},
    {value: "WA", label: "WA"},
    {value: "WV", label: "WV"},
    {value: "WI", label: "WI"},
    {value: "WY", label: "WY"}
];
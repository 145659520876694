import {useState, useCallback, ChangeEvent, SyntheticEvent} from "react";
import {HighSchool, UserProfileState, SelectItem, EditedPhoneState} from "../../data-types";
import {DataAccess, DataValidation} from "../../util";
import {isAfter, subYears} from "date-fns";

export const useProfileEdit = (user: UserProfileState) => {

    const [editedProfileState, setEditedProfileState] = useState<UserProfileState>(() => {
        let usResidentValue = user.usResident === null ? null : user.usResident;
        return {
            ...user,
            usResident: usResidentValue
        }
    });
    const [editedPhoneState, setEditedPhoneState] = useState<EditedPhoneState>(() => {
        return {
            phone: "+" + (user.phoneCountryCode ?? "") + (user.phone ?? ""),
            phoneCountryCode: user.phoneCountryCode ?? "",
        };
    });

    const [isModified, setIsModified] = useState<boolean>(false);
    const [errorHighlight, setErrorHighlight] = useState<string>("");

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let {name, value, checked}: {name: string, value: string | number | null | boolean, checked: boolean} = e.target;
        if (name === "ageBracket") {
            let parsed = parseInt(value);
            value = (parsed === -1) ? null : parsed;
        } else if (name === "usResident") {
            value = !!value;
            if (value) {
                setEditedProfileState(prev => {
                    return {...prev, usResident: true, regionOrCountry: "US"}
                });
            } else {
                setEditedProfileState(prev => {
                    return {...prev, usResident: false, regionOrCountry: "Not Specified"};
                })
            }
            setIsModified(true);
            return;
        } else if (name === "smsOptIn") {
            value = checked;
        } else {
            value = (value === "Not Specified") ? null : value;
        }

        setEditedProfileState(prev => {
            return {...prev, [name]: value};
        });

        setIsModified(true);

    }, []);

    const handleDateChange = useCallback((dateKey: string, dateValue: Date | null) => {
        if (dateValue) {
            setEditedProfileState(prev => {
                return {
                    ...prev,
                    [dateKey]: dateValue
                }
            });
            setIsModified(true);
        }
    }, []);

    const handlePhoneChange = useCallback((phone: string, phoneCountryCode: string) => {
        setEditedPhoneState({phone: phone, phoneCountryCode: phoneCountryCode});
        if (phoneCountryCode !== "1") {
            setEditedProfileState(prev => {return {...prev, smsOptIn: false}});
        }
    }, []);

    const handleHighSchoolChange = useCallback((highSchool: HighSchool) => {
        setEditedProfileState(prev => {
            return {
                ...prev,
                highSchoolName: highSchool.highSchoolName,
                highSchoolId: highSchool.highSchoolId.toString(),
                highSchoolCity: highSchool.highSchoolCity,
                highSchoolState: highSchool.highSchoolState
            }
        });
        setIsModified(true);
    }, []);

    const handleSelectChange = useCallback((e: SyntheticEvent, v: SelectItem | null, key: string) => {
        if (v === null) {
            return;
        } else {
            setEditedProfileState(prev => {
                return {
                    ...prev,
                    [key]: v.value
                }
            });
            setIsModified(true);
        }
    }, []);

    const handleSetModified = useCallback(() => {
        setIsModified(true);
    }, []);

    const submitProfileEdits = useCallback(async (residentOnly: boolean, birthdayOnly: boolean, competitionId?: number) => {

        // SCI-219 validate only US zips
        const validate = async () => {
            // must always have a birthday
            if (editedProfileState.dateOfBirth === null || !DataValidation.isValidDate(editedProfileState.dateOfBirth) || isAfter(subYears(new Date(), 120), editedProfileState.dateOfBirth)) {
                setErrorHighlight("dateOfBirth");
                throw new Error("Date of Birth is invalid.");
            }
            if (isAfter(editedProfileState.dateOfBirth, subYears(new Date(), 13))) {
                setErrorHighlight("dateOfBirth");
                throw new Error("Date of Birth must be no less than 13 years ago.");
            }

            if (!birthdayOnly) {

                if (residentOnly) {
                    if (editedProfileState.usResident === null) {
                        setErrorHighlight("usResident");
                        throw new Error("Please complete residency question.");
                    }
                } else {
                    if (!DataValidation.isUserProfileComplete(editedProfileState, editedPhoneState)) {
                        setErrorHighlight("required");
                        throw new Error("Please complete required user profile fields marked with *");
                    }
                    if (editedProfileState.mailingZip !== null && !DataValidation.isValidZipCode(editedProfileState.mailingZip)) {
                        setErrorHighlight("mailingZip");
                        throw new Error("Invalid Mailing Zip Code.  Zip Code must be in form XXXXX or XXXXX-XXXX");
                    }
                    if (editedProfileState.gpa !== null && !DataValidation.isValidGpa(editedProfileState.gpa)) {
                        setErrorHighlight("gpa");
                        throw new Error("GPA must be between 0.00 and 5.00.  Please include two decimal places.");
                    }
                    if (isAfter(editedProfileState.dateOfBirth!, new Date())) {
                        setErrorHighlight("dateOfBirth");
                        throw new Error("Date of Birth must be in the past.");
                    }

                    // basic phone validation.  This is pretty basic validation:  if this is a
                    // US number (+1 dial code), then check for 10 digits.  No other validation
                    // for non-US numbers at the moment.  If this is eventually required, then
                    // use https://www.npmjs.com/package/google-libphonenumber
                    if (!editedPhoneState.phone || !editedPhoneState.phoneCountryCode) {
                        setErrorHighlight("phone");
                        throw new Error("Invalid phone number");
                    } else {
                        let [_, b] = editedPhoneState.phone.split("+" + editedPhoneState.phoneCountryCode);
                        if (!b) {
                            setErrorHighlight("phone");
                            throw new Error("Invalid phone number");
                        }
                        // if NA +1 number, check for 10 digits
                        if (editedPhoneState.phoneCountryCode === "1" && b?.length !== 10) {
                            setErrorHighlight("phone");
                            throw new Error("Invalid phone number");
                        }
                    }
                }
            }
        }

        await validate();
        const [_, newPhone] = editedPhoneState.phone.split("+" + editedPhoneState.phoneCountryCode);
        const data = {
            ...editedProfileState,
            gpa: editedProfileState.gpa ? parseFloat(editedProfileState.gpa.toString()) : null,
            phone: newPhone,
            phoneCountryCode: editedPhoneState.phoneCountryCode,
            competitionId: competitionId
        }
        await DataAccess.post("/api/user/storeDemographicData.json", {data: data});
        setIsModified(false);

    }, [editedProfileState, editedPhoneState]);

    return {
        editedProfileState: editedProfileState,
        handleChange: handleChange,
        handlePhoneChange: handlePhoneChange,
        handleDateChange: handleDateChange,
        submitProfileEdits: submitProfileEdits,
        handleHighSchoolChange: handleHighSchoolChange,
        handleSelectChange: handleSelectChange,
        isModified: isModified,
        errorHighlight: errorHighlight,
        editedPhoneState: editedPhoneState,
        handleSetModified: handleSetModified
    }
}
import React, {SyntheticEvent, useState} from "react";
import "react-international-phone/style.css";
import {keyframes} from "@mui/system";
import {Box, Grid2 as Grid, Typography, TextField, MenuItem, Button, FormControlLabel, Checkbox, Autocomplete} from "@mui/material";
import {StateOptions, GenderOptions, EthnicityOptions, CountryOptions, LanguageOptions, DataValidation, DataFormatting} from "../../util";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {format, subYears} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
import {HighSchool, SelectItem, UserProfileState, EditedPhoneState} from "../../data-types";
import {HighSchoolSearchModal} from "../../components";
import {SxProps} from "@mui/material/styles";
import {PhoneField} from "./PhoneField";
import ErrorIcon from '@mui/icons-material/Error';

interface UserProfileFormProps {
    currentProfileState: UserProfileState;
    editedProfileState: UserProfileState;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handlePhoneChange:  (phone: string, phoneCountryCode: string) => void;
    handleDateChange:  (dateKey: string, dateValue: Date | null) => void;
    handleHighSchoolChange: (highSchool: HighSchool) => void;
    handleSelectChange: (e: SyntheticEvent, v: SelectItem | null, key: string) => void;
    isModified: boolean;
    residentOnly: boolean;
    requiredOnly: boolean;
    birthdayOnly: boolean;
    submit: () => void;
    handleCancel?: () => void;
    errorHighlight: string;
    editedPhoneState: EditedPhoneState;
    handleSetModified: () => void;
}

const pulse = keyframes`
    0% {
        background: rgb(255, 195, 195, 0.1);
    }

    70% {
        background: rgb(255, 195, 195, 0.5);
    }

    100% {
        background: rgb(255, 195, 195, 0.1);
    }`;

const errorPulseSx: SxProps = {animation: `${pulse} 1s infinite`};

export function UserProfileForm({currentProfileState, editedProfileState, handleChange, handleSelectChange, submit,
                                    handlePhoneChange, handleDateChange, handleHighSchoolChange, isModified,
                                    residentOnly, handleCancel, errorHighlight, editedPhoneState, handleSetModified,
                                    requiredOnly, birthdayOnly
            }: UserProfileFormProps) {
    const [highSchoolSearchOpen, setHighSchoolSearchOpen] = useState<boolean>(false);
    const [manualHighSchoolEntry, setManualHighSchoolEntry] = useState<boolean>(false);

    const handleHighSchoolSelect = (highSchool: HighSchool | null) => {
        if (highSchool) {
            handleHighSchoolChange(highSchool);
        } else {
            setManualHighSchoolEntry(true);
        }
        setHighSchoolSearchOpen(false);
    }

    const disableMailingAddress = editedProfileState.usResident === false;
    const incompleteFields = DataValidation.getUserProfileIncompleteFields(editedProfileState, editedPhoneState);

    if (residentOnly) {
        return (
            <Grid container>
                <Grid container size={{xs: 12}} alignItems="center" justifyContent="center">
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Address and Contact
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12 ,sm: 6}}>
                        <TextField
                            select
                            required
                            id="usResident"
                            label="Us Resident"
                            name="usResident"
                            fullWidth
                            sx={errorHighlight === "usResident" ? errorPulseSx : undefined}
                            onChange={handleChange}
                            value={
                                (editedProfileState.usResident === undefined ||
                                    editedProfileState.usResident === null) ?
                                    "Not Specified" :
                                    (editedProfileState.usResident) ?
                                        1 :
                                        0
                            }
                        >
                            <MenuItem
                                value={"Not Specified"}
                                disabled
                            >
                                Not Specified
                            </MenuItem>
                            <MenuItem
                                value={1}
                            >
                                Yes
                            </MenuItem>
                            <MenuItem
                                value={0}
                            >
                                No
                            </MenuItem>
                        </TextField>
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${(currentProfileState.usResident === undefined ||
                                currentProfileState.usResident === null) ?
                                "Not Specified" :
                                (currentProfileState.usResident) ?
                                    "Yes" :
                                    "No"}`}
                        </Typography>

                    </Grid>
                </Grid>
                {currentProfileState.dateOfBirth === null ?
                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Date of Birth
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disableFuture
                                label="Date of Birth"
                                sx={errorHighlight === "dateOfBirth" ? errorPulseSx : undefined}
                                // if null default to 17 years ago
                                minDate={subYears(new Date(), 100)}
                                maxDate={subYears(new Date(), 13)}
                                openTo="year"
                                views={["year", "month", "day"]}
                                yearsOrder="desc"
                                value={editedProfileState.dateOfBirth}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        fullWidth: true
                                    }
                                }}
                                onChange={(value) => handleDateChange("dateOfBirth", value)}
                            />
                        </LocalizationProvider>
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${currentProfileState.dateOfBirth ? format(currentProfileState.dateOfBirth, "MM/dd/yyyy") : "Not Specified"}`}
                        </Typography>
                    </Grid> :
                    null
                }
                <Grid size={{xs: 12, sm: 6}} offset={{xs: 0, sm: 6}} sx={{mt: 3, textAlign: "right"}}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={!isModified }
                        onClick={submit}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        )
    }

    if (birthdayOnly) {
       return (
           <Grid container>
               <Grid container size={{xs: 12}}>
                   <Grid size={{xs: 12, sm: 6, md: 4}}>
                       <Typography variant="h6" sx={{mb: 2}}>
                           Date of Birth
                       </Typography>
                       <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                               disableFuture
                               label="Date of Birth"
                               sx={errorHighlight === "dateOfBirth" ? errorPulseSx : undefined}
                               // if null default to 17 years ago
                               minDate={subYears(new Date(), 100)}
                               maxDate={subYears(new Date(), 13)}
                               openTo="year"
                               views={["year", "month", "day"]}
                               yearsOrder="desc"
                               value={editedProfileState.dateOfBirth}
                               slotProps={{
                                   textField: {
                                       required: true,
                                       fullWidth: true
                                   }
                               }}
                               onChange={(value) => handleDateChange("dateOfBirth", value)}
                           />
                       </LocalizationProvider>
                       <Typography variant="subtitle2" sx={{mt: 2}}>
                           {`Current:  ${currentProfileState.dateOfBirth ? format(currentProfileState.dateOfBirth, "MM/dd/yyyy") : "Not Specified"}`}
                       </Typography>
                   </Grid>
                   <Grid size={{xs: 12, sm: 6}} offset={{xs: 0, sm: 6}} sx={{mt: 3, textAlign: "right"}}>
                       <Button
                           variant="contained"
                           fullWidth
                           color="primary"
                           disabled={!isModified }
                           onClick={submit}
                       >
                           Save
                       </Button>
                   </Grid>
               </Grid>
           </Grid>
       )
    }

    return (
        <>
            <Grid container spacing={{md: 4, xs: 2, sm: 2}} justifyContent="align" alignItems="center" rowSpacing={6}>
                <Grid size={{xs: 12}}>
                    <Typography variant="h4">
                        Address and Contact
                    </Typography>
                </Grid>
                <Grid container size={{xs: 12}} alignItems="center" justifyContent="center">
                    <Grid size={{xs: 12, sm: 6}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Are you a US Resident?
                        </Typography>
                        <TextField
                            select
                            required
                            id="usResident"
                            label="Us Resident"
                            name="usResident"
                            fullWidth
                            sx={errorHighlight === "usResident" ? errorPulseSx : undefined}
                            onChange={handleChange}
                            value={
                                (editedProfileState.usResident === undefined ||
                                    editedProfileState.usResident === null) ?
                                    "Not Specified" :
                                    (editedProfileState.usResident) ?
                                        1 :
                                        0
                            }
                        >
                            <MenuItem
                                value={"Not Specified"}
                                disabled
                            >
                                Not Specified
                            </MenuItem>
                            <MenuItem
                                value={1}
                            >
                                Yes
                            </MenuItem>
                            <MenuItem
                                value={0}
                            >
                                No
                            </MenuItem>
                        </TextField>
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${(currentProfileState.usResident === undefined ||
                                currentProfileState.usResident === null) ?
                                "Not Specified" :
                                (currentProfileState.usResident) ?
                                    "Yes" :
                                    "No"}`}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Country
                        </Typography>
                        <Autocomplete
                            options={CountryOptions}
                            getOptionLabel={(option) => option.label}
                            disabled={editedProfileState.usResident === true}
                            value={CountryOptions.find(o => o.value === editedProfileState.regionOrCountry) || null}
                            onChange={(e, v) => handleSelectChange(e, v, "regionOrCountry")}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required
                                    fullWidth
                                    id="regionOrCountry"
                                    label="Country"
                                    name="regionOrCountry"
                                />
                            }
                        />
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${CountryOptions.find(s => s.value === currentProfileState.regionOrCountry)?.label ?? "Not Specified"}`}
                        </Typography>
                    </Grid>
                    {requiredOnly ?
                        null :
                        <>
                            <Grid size={{xs: 12, sm: 8}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Address
                                </Typography>
                                <TextField
                                    id="mailingAddress1"
                                    disabled={disableMailingAddress}
                                    label="Mail Address"
                                    name="mailingAddress1"
                                    fullWidth
                                    onChange={handleChange}
                                    value={editedProfileState.mailingAddress1 ?? ""}
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${currentProfileState.mailingAddress1 || "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Address 2
                                </Typography>
                                <TextField
                                    id="mailingAddress2"
                                    disabled={disableMailingAddress}
                                    label="Mail Address 2"
                                    name="mailingAddress2"
                                    fullWidth
                                    onChange={handleChange}
                                    value={editedProfileState.mailingAddress2 ?? ""}
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${currentProfileState.mailingAddress2 || "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    City
                                </Typography>
                                <TextField
                                    id="city"
                                    disabled={disableMailingAddress}
                                    label="City"
                                    name="mailingCity"
                                    fullWidth
                                    onChange={handleChange}
                                    value={editedProfileState.mailingCity ?? ""}
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${currentProfileState.mailingCity || "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    State
                                </Typography>
                                <Autocomplete
                                    options={StateOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={StateOptions.find(o => o.value === editedProfileState.mailingState) || null}
                                    onChange={(e, v) => handleSelectChange(e, v, "mailingState")}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="mailingState"
                                            label="State"
                                            name="mailingState"
                                        />
                                    }
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${currentProfileState.mailingState || "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Zip code
                                </Typography>
                                <TextField
                                    id="mailingZip"
                                    disabled={disableMailingAddress}
                                    label="Zip code"
                                    fullWidth
                                    sx={errorHighlight === "mailingZip" ? errorPulseSx : undefined}
                                    name="mailingZip"
                                    onChange={handleChange}
                                    value={editedProfileState.mailingZip ?? ""}
                                    slotProps={{
                                        htmlInput: {maxLength: 10}
                                    }}
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${currentProfileState.mailingZip || "Not Specified"}`}
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid size={{xs: 0, sm: 0, md: 2}} />
                    <Grid size={{xs: 12, sm: 12, md: 4}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Phone
                        </Typography>
                        <PhoneField
                            value={editedPhoneState.phone}
                            onChange={handlePhoneChange}
                            sx={errorHighlight === "phone" ? errorPulseSx : undefined}
                            handleSetModified={handleSetModified}
                        />
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${currentProfileState.phone ? DataFormatting.formatPhone(currentProfileState.phone) : "Not Specified"}`}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 4}}>
                        <FormControlLabel
                            sx={{my: 2}}
                            control={
                                <Checkbox
                                    checked={editedProfileState.smsOptIn}
                                    disabled={editedPhoneState.phoneCountryCode !== "1"}
                                    onChange={handleChange}
                                />
                            }
                            label="*Text me reminders about The Grit Test deadlines and upcoming events"
                            name="smsOptIn"
                        />
                    </Grid>
                    <Grid size={{xs: 0, sm: 0, md: 2}} />
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2" sx={{textAlign: "center"}}>
                            * You may incur data costs from your carrier for messages. You may opt-out at any time by unchecking this box.  Available to US numbers only.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container size={{xs: 12}} alignItems="center">
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            High School Details
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}} offset={{xs: 0, sm: 3}}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={disableMailingAddress}
                            onClick={() => setHighSchoolSearchOpen(true)}
                            startIcon={<SearchIcon />}
                        >
                            Click here to search for your high school
                        </Button>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            High School Name
                        </Typography>
                        <TextField
                            required
                            id="highSchoolName"
                            disabled={!manualHighSchoolEntry}
                            label="High School Name"
                            name="highSchoolName"
                            fullWidth
                            onChange={handleChange}
                            value={editedProfileState.highSchoolName ?? ""}
                        />
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${currentProfileState.highSchoolName || "Not Specified"}`}
                        </Typography>
                    </Grid>
                    {requiredOnly ?
                        null :
                        <Grid size={{xs: 12, sm: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                High School Counselor
                            </Typography>
                            <TextField
                                id="highSchoolCounselor"
                                label="High School Counselor"
                                name="highSchoolCounselor"
                                fullWidth
                                onChange={handleChange}
                                value={editedProfileState.highSchoolCounselor ?? ""}
                            />
                            <Typography variant="subtitle2" sx={{mt: 2}}>
                                {`Current:  ${currentProfileState.highSchoolCounselor || "Not Specified"}`}
                            </Typography>
                        </Grid>
                    }
                    <Grid size={{xs: 12, sm: 6}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            High School City
                        </Typography>
                        <TextField
                            required
                            id="highSchoolCity"
                            disabled={!manualHighSchoolEntry}
                            label="High School City"
                            name="highSchoolCity"
                            fullWidth
                            onChange={handleChange}
                            value={editedProfileState.highSchoolCity ?? ""}
                        />
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${currentProfileState.highSchoolCity || "Not Specified"}`}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            High School State
                        </Typography>
                        <Autocomplete
                            options={StateOptions}
                            getOptionLabel={(option) => option.label}
                            disabled={!manualHighSchoolEntry}
                            value={StateOptions.find(o => o.value === editedProfileState.highSchoolState) || null}
                            onChange={(e, v) => handleSelectChange(e, v, "highSchoolState")}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required
                                    fullWidth
                                    id="highSchoolState"
                                    label="High School State"
                                    name="highSchoolState"
                                />
                            }
                        />
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${currentProfileState.highSchoolState || "Not Specified"}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container size={{xs: 12}} alignItems="start" justifyContent="space-between">
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Demographics
                        </Typography>
                    </Grid>
                    {requiredOnly ?
                        null :
                        <>
                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Primary Language
                                </Typography>
                                <Autocomplete
                                    options={LanguageOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={LanguageOptions.find(o => o.value === editedProfileState.primaryLanguage) || null}
                                    onChange={(e, v) => handleSelectChange(e, v, "primaryLanguage")}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="primaryLanguage"
                                            label="Primary Language"
                                            name="primaryLanguage"
                                        />
                                    }
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${LanguageOptions.find(s => s.value === currentProfileState.primaryLanguage)?.label ?? "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Secondary Language
                                </Typography>
                                <Autocomplete
                                    options={LanguageOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={LanguageOptions.find(o => o.value === editedProfileState.secondaryLanguage) || null}
                                    onChange={(e, v) => handleSelectChange(e, v, "secondaryLanguage")}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="secondaryLanguage"
                                            label="Secondary Language"
                                            name="secondaryLanguage"
                                        />
                                    }
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${LanguageOptions.find(s => s.value === currentProfileState.secondaryLanguage)?.label ?? "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Ethnicity
                                </Typography>
                                <Autocomplete
                                    options={EthnicityOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={EthnicityOptions.find(o => o.value === editedProfileState.ethnicity) || null}
                                    onChange={(e, v) => handleSelectChange(e, v, "ethnicity")}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="ethnicity"
                                            label="Ethnicity"
                                            name="ethnicity"
                                        />
                                    }
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${EthnicityOptions.find(s => s.value === currentProfileState.ethnicity)?.label ?? "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Gender
                                </Typography>
                                <Autocomplete
                                    options={GenderOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={GenderOptions.find(o => o.value === editedProfileState.gender) || null}
                                    onChange={(e, v) => handleSelectChange(e, v, "gender")}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            id="gender"
                                            label="Gender"
                                            name="gender"
                                        />
                                    }
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${GenderOptions.find(s => s.value === currentProfileState.gender)?.label ?? "Not Specified"}`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    High School GPA
                                </Typography>
                                <TextField
                                    label="GPA"
                                    name="gpa"
                                    id="gpa"
                                    sx={errorHighlight === "gpa" ? errorPulseSx : undefined}
                                    helperText="Min: 0.00, Max 5.00.  Format: x.xx or x.x "
                                    error={!DataValidation.isValidGpa(editedProfileState.gpa) && editedProfileState.gpa !== null}
                                    fullWidth
                                    onChange={handleChange}
                                    value={editedProfileState.gpa ?? ""}
                                />
                                <Typography variant="subtitle2" sx={{mt: 2}}>
                                    {`Current:  ${currentProfileState.gpa ?? "Not Specified"}`}
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Date of Birth
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disableFuture
                                label="Date of Birth"
                                sx={errorHighlight === "dateOfBirth" ? errorPulseSx : undefined}
                                // if null default to 17 years ago
                                minDate={subYears(new Date(), 100)}
                                maxDate={subYears(new Date(), 13)}
                                openTo="year"
                                views={["year", "month", "day"]}
                                yearsOrder="desc"
                                value={editedProfileState.dateOfBirth ?? null}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        fullWidth: true,
                                        value: editedProfileState.dateOfBirth
                                    },
                                    field: {
                                        readOnly: true
                                    },
                                    desktopTrapFocus: {
                                        disableEnforceFocus: true,
                                        disableRestoreFocus: true,
                                        disableAutoFocus: true
                                    }
                                }}
                                onChange={(value) => handleDateChange("dateOfBirth", value)}
                            />
                        </LocalizationProvider>
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Current:  ${currentProfileState.dateOfBirth ? format(currentProfileState.dateOfBirth, "MM/dd/yyyy") : "Not Specified"}`}
                        </Typography>
                    </Grid>
                    {incompleteFields.length > 0 ?
                        <Grid container size={{xs: 12, sm: 6}} offset={{xs: 0, sm: 6}} alignItems="center" justifyContent="space-evenly">
                            <Grid>
                                <Typography variant="subtitle2">
                                    Please Complete:
                                </Typography>
                            </Grid>
                            <Grid>
                                {incompleteFields.map(c =>
                                    <Box key={c} sx={{display: "flex", alignItems: "center"}}>
                                        <ErrorIcon color="secondary" fontSize="small" sx={{mr: 2}} />
                                        <Typography variant="subtitle2" key={c}>
                                            {DataFormatting.camelCaseToWords(c)}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        </Grid> :
                        null
                    }
                    <Grid container size={{xs: 12}}>
                        <Grid size={{xs: 12, sm: 6}} sx={{mt: 1, textAlign: "right"}}>
                            {handleCancel ?
                                <Button
                                    variant="outlined"
                                    color="darkPrimary"
                                    fullWidth
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button> :
                                null
                            }
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}} sx={{mt: 1, textAlign: "right"}}>
                            <Button variant="contained"
                                    fullWidth
                                    color="primary"
                                    disabled={!isModified || !DataValidation.isUserProfileComplete(editedProfileState, editedPhoneState)}
                                    onClick={submit}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <HighSchoolSearchModal
                open={highSchoolSearchOpen}
                setOpen={setHighSchoolSearchOpen}
                handleHighSchoolSelect={handleHighSchoolSelect}
            />
        </>
    )
}
import React, {useCallback, useRef} from "react";
import {toast} from "react-toastify";
import {useDropzone} from "react-dropzone";
import {Box, Typography, Button} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {FileUploadConfig} from "../../data-types";
import {DataFormatting} from "../../util";

type AcceptedFileConfig = {
    [key: string]: string[];
}

interface FileDropUploadProps {
    maxFileSize: number;
    acceptedFileTypes: AcceptedFileConfig;
    uploadConfig: FileUploadConfig | null;
    setUploadConfig: React.Dispatch<React.SetStateAction<FileUploadConfig | null>>;
    disabled?: boolean;
    maxVideoDuration?: number; // seconds
}

export function FileDropUpload({maxFileSize, acceptedFileTypes, uploadConfig, setUploadConfig, disabled, maxVideoDuration}: FileDropUploadProps) {

    const videoRef = useRef<HTMLVideoElement>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const uploadedFile = acceptedFiles[0];
        if (!uploadedFile || uploadedFile.size > maxFileSize) {
            toast.error("That file is either invalid or too large.  Please try another file.");
        } else {
            if (maxVideoDuration) {
                const video = videoRef.current!;
                const url = URL.createObjectURL(uploadedFile);
                video.src = url;
                video.onloadedmetadata = () => {
                    const duration = video.duration;
                    if (duration > maxVideoDuration) {
                        toast.error("This video is too long.  Please upload a shorter video.");
                    } else {
                        setUploadConfig({file: uploadedFile, mimeType: uploadedFile.type, size: duration, fileName: uploadedFile.name});
                        URL.revokeObjectURL(url);
                    }
                }
            } else {
                setUploadConfig({file: uploadedFile, mimeType: uploadedFile.type, size: NaN, fileName: uploadedFile.name});
            }
        }
    }, [setUploadConfig, maxFileSize, maxVideoDuration]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: acceptedFileTypes, maxFiles: 1, disabled: disabled});

    const acceptedExtensions = Object.values(acceptedFileTypes)
        .reduce((p, c) => [...p, ...c], [])
        .join(", ");

    return (
        <Box {...getRootProps()} sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            padding: '25px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#555555',
            borderStyle: 'dashed',
            backgroundColor: '#f7f7f7',
            color: '#555555',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            textAlign: "center",
            height: 220
        }}
             component="div"
        >
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <Typography variant="h3">Drop Here!</Typography> :
                    <>
                        <Typography variant="body1">
                            Drag and drop a file here, or click to select files.
                        </Typography>
                        <Typography variant="body1">
                            {`Only ${acceptedExtensions} files are currently supported.`}
                        </Typography>
                        <Box
                            sx={{
                                my: 2,
                                py:1,
                                px: 3,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {uploadConfig ?
                                <CheckCircleIcon color="primary" /> :
                                <CancelIcon color="secondary" />
                            }
                            <Typography variant="body1" sx={{ml: 2}}>
                                {`${uploadConfig ? uploadConfig.fileName : "Nothing uploaded"}`}
                            </Typography>
                        </Box>
                        {uploadConfig ?
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setUploadConfig(null)
                                }}
                                color="secondary"
                            >
                                Remove Upload
                            </Button> :
                            null
                        }
                        <Typography variant="subtitle2">
                            {`Max File Size:  ${DataFormatting.intToByteString(maxFileSize)}`}
                        </Typography>
                        {maxVideoDuration ?
                            <Typography variant="subtitle2">
                                {`Max Video Duration:  ${DataFormatting.secondsToTimeString(maxVideoDuration)}`}
                            </Typography> :
                            null
                        }
                    </>
            }
            <video ref={videoRef} hidden />
        </Box>
    )
}
import React, {useContext} from 'react';
import {Grid2 as Grid, Typography, Container} from "@mui/material";
import {RankingSummary} from "./RankingSummary";
import {UserContext} from "../../context";
import {FindCompetitionsHomepage} from "./FindCompetitionsHomepage";
import {ActiveEvents} from "./ActiveEvents";
import {useEnroll, useMyEvents} from "../../hooks";
import {LegalAgreementModal, OptionalStepCompetitionModal, ProfileEditModal, GoToResponsesModal} from "../../components";

export function Home() {

    const {user} = useContext(UserContext);
    const {
        requestStatus,
        activeEvents,
        completedEvents
    } = useMyEvents();

    const {
        requestStatus: enrollmentRequestStatus,
        invitations,
        openCompetitions,
        rejectInvitation,
        enrollState,
        selectedInstitution,
        selectedCompetition,
        optionalScriptStepOptions,
        initEnroll,
        legalTransitionHandler,
        optionalStepTransitionHandler,
        handleSaveProfile,
        handleCloseGoToResponses,
        handleInstitutionSelect,
        handleCancel,
        goToResponsesCompetitionId,
        legalAgreementUuid
    } = useEnroll();

    // if active events > 0, then show those in top component
    // else, if invitations/open competitions > 0, then show those in top component
    // else, if completed events > 0, show rankings
    // else, active events > join event > rankings
    const activeEventsWithTodos = activeEvents.filter(c => {
        const required = c.roundStatus?.todo?.required || 0;
        const submitted = c.roundStatus?.todo?.submitted || 0;
        const phase = c.roundStatus?.phase || "";
        const activeTime = c.competitionMeta?.activeTime;
        const eliminated = c.participantStatus?.eliminated;
        const hasInstitutionsToSelect = c.roundStatus?.optionalScriptSteps && c.roundStatus.optionalScriptSteps.length !== 0
        return (((required - submitted > 0) && (phase === "response" || phase === "review")) || hasInstitutionsToSelect) && activeTime && !eliminated;
    });

    let activeOrder: number, joinOrder: number, rankOrder: number;
    const countActiveEvents = activeEventsWithTodos.length;
    const countToJoin = [...openCompetitions, ...invitations.current].length;
    const countCompleted = completedEvents.length;

    // avert ye eyes of me shame
    if (countActiveEvents > 0) {
        activeOrder = 1;
        if (countToJoin > 0) {
            joinOrder = 2;
            rankOrder = 3;
        } else {
            joinOrder = 3;
            rankOrder = 2;
        }
    } else if (countToJoin > 0) {
        joinOrder = 1;
        if (countCompleted > 0) {
            rankOrder = 2;
            activeOrder = 3;
        } else {
            activeOrder = 2;
            rankOrder = 3;
        }
    } else if (countCompleted > 0) {
        rankOrder = 1;
        activeOrder = 2;
        joinOrder = 3;
    } else {
        activeOrder = 1;
        joinOrder = 2;
        rankOrder = 3;
    }

    return (
        <Container sx={{mb: 10}}>
            <Grid container spacing={6}>
                <Grid size={{xs: 12, md: 8}}>
                    <Typography variant="h4">
                        Welcome back,
                        <Typography  variant="inherit" color="primary" display="inline">
                            {user ? " " + user.username : ""}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4} sx={{mt: 4}}>
                <Grid size={{xs: 12, sm: 12, md: (activeOrder > 1) ? 6 : 12}} order={{xs: activeOrder}}>
                    <ActiveEvents
                        activeEvents={activeEventsWithTodos}
                        requestStatus={requestStatus}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: (joinOrder > 1) ? 6 : 12}} order={{xs: joinOrder}}>
                    <FindCompetitionsHomepage
                        requestStatus={enrollmentRequestStatus}
                        openCompetitions={openCompetitions}
                        invitations={invitations}
                        forceXsBreakpoint={joinOrder > 1}
                        initEnroll={initEnroll}
                        reject={rejectInvitation}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: (rankOrder > 1) ? 6 : 12}} order={{xs: rankOrder}}>
                    <RankingSummary
                        competitions={completedEvents}
                        requestStatus={requestStatus}
                    />
                </Grid>
            </Grid>
            <LegalAgreementModal
                open={enrollState === "legal"}
                confirmAction={legalTransitionHandler}
                legalAgreementUuid={legalAgreementUuid}
                handleCancel={handleCancel}
            />
            <OptionalStepCompetitionModal
                open={enrollState === "institutionSelect"}
                confirmAction={optionalStepTransitionHandler}
                optionalScriptSteps={optionalScriptStepOptions}
                selected={selectedInstitution}
                handleInstitutionSelect={handleInstitutionSelect}
                handleCancel={handleCancel}
            />
            <GoToResponsesModal
                competitionId={goToResponsesCompetitionId}
                close={handleCloseGoToResponses}
            />
            <ProfileEditModal
                open={enrollState === "profileEdit" || enrollState === "profileEditResident" || enrollState === "profileEditBirthday"}
                onClose={handleCancel}
                user={user!}
                handleCancel={handleCancel}
                handleSave={handleSaveProfile}
                selectedCompetition={selectedCompetition}
                residentOnly={enrollState === "profileEditResident"}
                birthdayOnly={enrollState === "profileEditBirthday"}
            />
        </Container>
    )
}

import React, {useContext} from "react";
import {Container, Grid2 as Grid, Typography, Tabs, Tab} from "@mui/material";
import {LoadingSpinner, ErrorComponent, LegalAgreementModal, OptionalStepCompetitionModal, ProfileEditModal, GoToResponsesModal} from "../../components";
import {OpenEnrollSection} from "./OpenEnrollSection";
import {InvitationSection} from "./InvitationSection";
import {useEnroll} from "../../hooks";
import {UserContext} from "../../context";

export function Enroll() {

    const {user} = useContext(UserContext);
    const {
        requestStatus,
        invitations,
        openCompetitions,
        enrollTab,
        handleTabChange,
        rejectInvitation,
        enrollState,
        selectedInstitution,
        selectedCompetition,
        optionalScriptStepOptions,
        initEnroll,
        legalTransitionHandler,
        optionalStepTransitionHandler,
        handleSaveProfile,
        handleCloseGoToResponses,
        handleInstitutionSelect,
        handleCancel,
        goToResponsesCompetitionId,
        legalAgreementUuid
    } = useEnroll();

    const countInvitations = invitations.current.length;
    const countAvailable = openCompetitions.length;

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Enroll Events
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Tabs
                            value={enrollTab}
                            onChange={handleTabChange}
                            textColor="inherit"
                        >
                            <Tab label={`Invitations ${countInvitations > 0 ? `(${countInvitations})` : ""}`} value="invitations" />
                            <Tab label={`Available ${countAvailable > 0 ? `(${countAvailable})` : ""}`} value="available" />
                        </Tabs>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        {enrollTab === "available" ?
                            <OpenEnrollSection
                                openCompetitions={openCompetitions}
                                initEnroll={initEnroll}
                            /> :
                            <InvitationSection
                                invitations={invitations}
                                initAccept={initEnroll}
                                reject={rejectInvitation}
                            />
                        }
                    </Grid>
                </Grid>
                <LegalAgreementModal
                    open={enrollState === "legal"}
                    confirmAction={legalTransitionHandler}
                    legalAgreementUuid={legalAgreementUuid}
                    handleCancel={handleCancel}
                />
                <OptionalStepCompetitionModal
                    open={enrollState === "institutionSelect"}
                    confirmAction={optionalStepTransitionHandler}
                    optionalScriptSteps={optionalScriptStepOptions}
                    selected={selectedInstitution}
                    handleInstitutionSelect={handleInstitutionSelect}
                    handleCancel={handleCancel}
                />
                <GoToResponsesModal
                    competitionId={goToResponsesCompetitionId}
                    close={handleCloseGoToResponses}
                />
                <ProfileEditModal
                    open={enrollState === "profileEdit" || enrollState === "profileEditResident" || enrollState === "profileEditBirthday"}
                    onClose={handleCancel}
                    user={user!}
                    handleCancel={handleCancel}
                    handleSave={handleSaveProfile}
                    residentOnly={enrollState === "profileEditResident"}
                    birthdayOnly={enrollState === "profileEditBirthday"}
                    selectedCompetition={selectedCompetition}
                />
            </Container>
        )
    }
}
import React, {useState, useCallback, ChangeEvent} from "react";
import {Modal, Typography, TextField, Divider, Button, Grid2 as Grid} from "@mui/material";
import {DataValidation, formatError} from "../../util";
import {ModalBox} from "../modal-box";
import {toast} from "react-toastify";

interface UserShareEventModalProps {
    open: boolean;
    availableUsers: number;
    submit: (emails: string[]) => void;
    onClose: () => void;
}

export function UserShareEventModal({open, availableUsers, submit, onClose}: UserShareEventModalProps) {

    const [usersToInvite, setUsersToInvite] = useState<string[]>(["", "", "", "", ""]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        // name is index of array to change
        const index = parseInt(name);
        setUsersToInvite(prev => prev.map((c, i) => index === i ? value : c));
    }, []);

    const onSubmit = () => {
        // check that emails are ok
        const validate = async () => {
            const allBlank = usersToInvite.every(c => c === "");
            const anyInvalid = usersToInvite.some(c => c !== "" && !DataValidation.isValidEmailAddress(c));

            if (allBlank) {
                throw new Error("Please enter at least one valid email address");
            }
            if (anyInvalid) {
                throw new Error("One of the email addresses you entered is invalid.  Please try again.");
            }
        }

        validate()
            .then(() => {
                submit(usersToInvite.filter(c => c !== ""));
            })
            .catch(e => {
                toast.error(formatError(e));
            });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalBox>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5">
                            Share Event
                        </Typography>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            {`You may invite ${availableUsers} ${availableUsers === 1 ? "person" : "people"} to join this event.  Please enter their email addresses below.`}
                        </Typography>
                    </Grid>
                    {usersToInvite.map((c, i) => (i <= availableUsers - 1) ?
                        <Grid size={{xs: 12, sm: 6}} key={i}>
                            <TextField
                                id={`participant${i + 1}`}
                                name={i.toString()}
                                value={c}
                                onChange={handleChange}
                                label={`Participant ${i + 1} Email`}
                                fullWidth
                            />
                        </Grid> :
                        null
                    )}
                </Grid>
                <Grid container size={{xs: 12}} sx={{mt: 2}} spacing={2}>
                    <Grid size={{xs: 6}}>
                        <Button
                            variant="outlined"
                            color="darkPrimary"
                            fullWidth
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={onSubmit}
                        >
                            Invite
                        </Button>
                    </Grid>
                </Grid>
            </ModalBox>
        </Modal>
    )
}
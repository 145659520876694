import React from "react";
import {Container, Grid2 as Grid, Typography, Divider, Button, Paper} from "@mui/material";
import {useParams, Link} from "react-router-dom";
import {usePaymentSuccess} from "./usePaymentSuccess";
import {LoadingSpinner, ErrorComponent} from "../../components";
import {parseISO, isAfter} from "date-fns";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function PaymentSuccess() {

    const {competitionId} = useParams();
    const {
        requestStatus,
        paymentSuccessDetails
    } = usePaymentSuccess(competitionId);

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {

        const now = new Date();
        const firstRoundStartDate = parseISO(paymentSuccessDetails.firstRoundStart + "Z");
        const firstRoundEndDate = parseISO(paymentSuccessDetails.firstRoundEnd + "Z");
        const goToResponses: boolean = isAfter(now, firstRoundStartDate) && isAfter(firstRoundEndDate, now);

        return (
            <Container sx={{mb: 10}} maxWidth="sm">
                <Paper elevation={0} sx={{p: 3}}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid size={{xs: 1}}>
                            <CheckCircleIcon color="primary" />
                        </Grid>
                        <Grid size={{xs: 11}}>
                            <Typography variant="h5">
                                Thank you for your purchase!
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Divider sx={{my: 2}} />
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Typography variant="subtitle2">
                                {"We've received your payment and you're now enrolled in "}
                                <Link to="/myEvents">
                                    {paymentSuccessDetails.name}.
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12}} sx={{mb: 3}}>
                            <Typography variant="subtitle2">
                                Amount Paid:  {`$${(paymentSuccessDetails.paymentParticipant / 100).toFixed(2)}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}} offset={{xs: 0, sm: goToResponses ? 0 : 6}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                component={Link}
                                to={`/receipt/${paymentSuccessDetails.competitionId}`}
                            >
                                View your Receipt
                            </Button>
                        </Grid>
                        {goToResponses ?
                            <Grid size={{xs: 12, sm: 6}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    fullWidth
                                    to={`/video-response/${paymentSuccessDetails.competitionId}`}
                                >
                                    Submit your Responses
                                </Button>
                            </Grid> :
                            null
                        }
                    </Grid>
                </Paper>
            </Container>
        )
    }
}
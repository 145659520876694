import {useCallback} from "react";
import {DataAccess} from "../../util";
import {UserProfileState} from "../../data-types";
import {parseISO} from "date-fns";

export const  useUserProfile = () => {

    return useCallback(async (): Promise<UserProfileState> => {
        const response = await DataAccess.get("/api/user/profile.json");
        const profile = response.userProfile as UserProfileState;

        const dobString = profile.dateOfBirth ? profile.dateOfBirth.toString() : null;
        return {...profile, dateOfBirth: dobString ? parseISO(dobString + "Z") : null}
    }, []);
}
import {ChangeEvent, useState, useCallback} from "react";
import {CreateRoundState} from "../../data-types";
import {isAfter, sub, addMinutes} from "date-fns";
import {DataAccess} from "../../util";
import {toast} from "react-toastify";

export const useRoundCreate = (competitionId: string | undefined, roundNumber: string | undefined) => {

    const [roundState, setRoundState] = useState<CreateRoundState>(() => {
        const now = new Date();
        now.setSeconds(0);
        now.setMilliseconds(0);
        return {
            competitionId: (competitionId) ? parseInt(competitionId) : NaN,
            scriptId: -1,
            responseStartDate: addMinutes(now, 7 * 24 * 60), // one week from now
            responseEndDate: addMinutes(now, 2 * 7 * 24 * 60), // two weeks from now
            responseReminderDaysBefore: 0,
            reviewStartDate: addMinutes(now, 10 + (2 * 7 * 24 * 60)), // two weeks + 10 mins from now
            reviewEndDate: addMinutes(now, 10 + (3 * 7 * 24 * 60)), // three weeks + 10 mins from now
            reviewReminderDaysBefore: 0,
            hasProxy: false,
            proxyStartDate: addMinutes(now, 20 + (3 * 7 * 24 * 60)), // three weeks + 20 mins from now
            proxyEndDate: addMinutes(now, 20 + (4 * 7 * 24 * 60)), // four weeks + 20 mins from now
            percentToProxy: 50,
            proxyReviewGroupId: -1,
            proxyHeatSize: 6,
            heatSize: 6,
            eliminationRate: 0.1,
            attributes: {
                include: [],
                exclude: []
            },
            roundNumber: (roundNumber) ? parseInt(roundNumber) : NaN,
            hasStandardAttributes: false,
            allowReduceHeatSize: true
        }
    });

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        // event.target.value is a string type.  for number types in state, check if
        // it can be converted to a number.  if so, then use that for value,
        // else use the string representation
        const value: string | number = (isNaN(e.target.valueAsNumber) ? e.target.value : e.target.valueAsNumber);
        setRoundState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, []);

    const handleDateChange = useCallback((dateKey: string, dateValue: Date | null) => {
        if (dateValue) {
            dateValue.setSeconds(0);
            dateValue.setMilliseconds(0);
            setRoundState(prev => {
                return {
                    ...prev,
                    [dateKey]: dateValue
                }
            });
        }
    }, []);

    const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        if (name === "allowReduceHeatSize" && !checked) {
            toast.warning("Unchecking this option reduces efficiency and can lead to an event not completing successfully.");
        }
        setRoundState(prev => {
            return {
                ...prev,
                [name]: checked
            }
        })
    }, []);

    const handleAddAttribute = useCallback((attribute: string) => {
        const a = attribute.trim();
        if (a.length > 0) {
            setRoundState(prev => {
                return {
                    ...prev,
                    attributes: {
                        ...prev.attributes,
                        include: [...prev.attributes.include, a]
                    }
                }
            });
        }
    }, []);

    const handleRemoveAttribute = useCallback((attribute: string) => {
        setRoundState(prev => {
            return {
                ...prev,
                attributes: {
                    // remove from the include array and add to exclude array
                    include: prev.attributes.include.filter(a => a !== attribute),
                    exclude: [...prev.attributes.exclude, attribute]
                }
            }
        })
    }, []);


    const createRound = useCallback(async (roundState: CreateRoundState) => {
        const isNewRoundValid = async () => {
            // check that start times come before end times
            if (isAfter(roundState.responseStartDate, roundState.responseEndDate)) {
                throw new Error("Please ensure the response start date is before the response end date");
            }
            if (isAfter(roundState.reviewStartDate, roundState.reviewEndDate)) {
                throw new Error("Please ensure the review start date is before the review end date");
            }
            // check reminder email
            if (isAfter(roundState.responseStartDate, sub(roundState.responseEndDate, {days: roundState.responseReminderDaysBefore}))) {
                throw new Error("Response Phase Reminder must be after Response Phase Start");
            }

            // check if the reviewStartDate is after the responseEndDate
            if (isAfter(roundState.responseEndDate, roundState.reviewStartDate)) {
                throw new Error("Please ensure the response phase ends before the review phase starts");
            }
            // check reminder email
            if (isAfter(roundState.reviewStartDate, sub(roundState.reviewEndDate, {days: roundState.reviewReminderDaysBefore}))) {
                throw new Error("Review Phase Reminder must be after Review Phase Start");
            }

            // check that a script was chosen
            if (roundState.scriptId === -1) {
                throw new Error("Please select a response script to assign to this round");
            }

            // checks for proxy phase
            if (roundState.hasProxy) {
                if (roundState.proxyReviewGroupId === -1) {
                    throw new Error("Please choose a review group for the Proxy review round");
                }
                if (roundState.percentToProxy <= 0 || roundState.percentToProxy > 100) {
                    throw new Error("Please ensure percent to proxy is between 0 and 100%");
                }
                if (roundState.proxyHeatSize <= 0) {
                    throw new Error("Please select a proxy heat size greater than 0");
                }
            }

        }

        await isNewRoundValid();
        const data = {round: roundState};
        try {
            await DataAccess.post("/api/round/create.json", {data: data})
        } catch (e) {
            throw new Error(DataAccess.handleError(e));
        }
    }, []);

    return {
        roundState: roundState,
        handleChange: handleChange,
        handleDateChange: handleDateChange,
        handleAddAttribute: handleAddAttribute,
        handleRemoveAttribute: handleRemoveAttribute,
        handleCheckboxChange: handleCheckboxChange,
        createRound: createRound
    }
}
import React, {useState} from "react";
import {
    Button, Modal, Typography, Grid2 as Grid,
    TextField, Divider, List, ListItem, ListItemText, Autocomplete
} from "@mui/material";
import {StateOptions} from "../../util";
import {ModalBox} from "../modal-box";
import SearchIcon from '@mui/icons-material/Search';
import {useHighSchoolSearch} from "../../hooks";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {HighSchool} from "../../data-types";

interface HighSchoolSearchModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleHighSchoolSelect: (highSchool: HighSchool | null) => void;
}

export function HighSchoolSearchModal({open, setOpen, handleHighSchoolSelect}: HighSchoolSearchModalProps) {

    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("Not Specified");
    const [countSearches, setCountSearches] = useState<number>(0);

    const {
        highSchoolSearchResults,
        searchHighSchools
    } = useHighSchoolSearch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (name === "highSchoolSearchCity") {
            setCity(value);
        } else {
            setState(value);
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <ModalBox>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5">
                            High School Search
                        </Typography>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <TextField
                            id="highSchoolSearchCity"
                            name="highSchoolSearchCity"
                            label="City"
                            fullWidth
                            value={city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Autocomplete
                            options={StateOptions}
                            getOptionLabel={(option) => option.label}
                            value={StateOptions.find(o => o.value === state) || null}
                            onChange={(e, v) => {
                                if (v) {
                                    setState(v.value as string);
                                }
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="highSchoolSearchState"
                                    label="State"
                                    name="highSchoolSearchState"
                                />
                            }
                        />
                    </Grid>
                    <Grid size={{xs: 6}}>
                        {countSearches > 0 ?
                            <Button
                                color="primary"
                                variant="outlined"
                                fullWidth
                                onClick={() => handleHighSchoolSelect(null)}
                            >
                                Enter Manually
                            </Button> :
                            null
                        }
                    </Grid>
                    <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            startIcon={<SearchIcon />}
                            onClick={() => {
                                searchHighSchools(city, state);
                                setCountSearches(p => p + 1)
                            }}
                            disabled={state === "Not Specified" || city.length === 0}
                        >
                            Search
                        </Button>
                    </Grid>
                    {highSchoolSearchResults.length > 0 ?
                        <Grid size={{xs: 12}}>
                            <List>
                                {highSchoolSearchResults.map(c =>
                                    <ListItem
                                        key={c.highSchoolCode}
                                        divider
                                        secondaryAction={
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                startIcon={<CheckCircleIcon />}
                                                onClick={() => handleHighSchoolSelect(c)}
                                                sx={{fontSize: 12}}
                                            >
                                                Select
                                            </Button>
                                        }
                                    >
                                        <ListItemText
                                            primary={c.highSchoolName}
                                            primaryTypographyProps={{fontSize: {xs: 11, sm: 16}}}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Grid> :
                        (countSearches > 0) ?
                            <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                                <Typography variant="subtitle2">
                                    No results found.  Please try again.
                                </Typography>
                            </Grid> :
                            null
                    }
                </Grid>
            </ModalBox>
        </Modal>
    )
}
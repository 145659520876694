import React from "react";
import {NotificationItemProps} from "../../data-types";
import {Typography, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import {Link} from "react-router-dom";

export function NotificationItem({data, handleRead, handleDelete}: NotificationItemProps) {

    let message;

    if (data.type === "TRANSCRIPT_WARNING") {
        let link = data.message.split("sci-ui/")[1];
        message = <Typography variant="subtitle2">
            We recommend editing the transcript of your upload.
            Edit the transcript <Typography component={Link} sx={{color: "#28871D"}} to={link}>here.</Typography>
        </Typography>
    } else if (data.type === "RESPONSE_START") {
        message =
            <>
                <Typography variant="subtitle2">
                    {data.message}
                </Typography>
                <Typography variant="subtitle2" sx={{color: "#28871D"}} component={Link} to={`/video-response/${data.resourceId}`}>
                    Link
                </Typography>
            </>
    } else if (data.type === "REVIEW_START") {
        message =
            <>
                <Typography variant="subtitle2">
                    {data.message}
                </Typography>
                <Typography variant="subtitle2" sx={{color: "#28871D"}} component={Link} to={`/video-review/${data.resourceId}`}>
                    Link
                </Typography>
            </>
    } else if (data.type === "PROXY_START") {
        message =
            <>
                <Typography variant="subtitle2">
                    {data.message}
                </Typography>
                <Typography variant="subtitle2" sx={{color: "#28871D"}} component={Link} to={`/reviewer/video-proxy-review/${data.resourceId}`}>
                    Link
                </Typography>
            </>
    } else if (data.type === "COMPETITION_END" || data.type === "EVENT_END") {
        message =
            <>
                <Typography variant="subtitle2">
                    {data.message}
                </Typography>
                <Typography variant="subtitle2" sx={{color: "#28871D"}} component={Link} to={`/myResults/${data.resourceId}/rankings`}>
                    Link
                </Typography>
            </>
    } else if (data.type === "REVIEW_REPORTED" || data.type === "PROXY_REPORTED" || data.type === "MODERATION_END" || data.type === "MODERATION_SUBMIT") {
        message =
            <>
                <Typography variant="subtitle2">
                    {data.message}
                </Typography>
                <Typography variant="subtitle2" sx={{color: "#28871D"}} component={Link} to={`/admin/round/detail/${data.resourceId}`}>
                    Link
                </Typography>
            </>
    } else {
        message = <Typography variant="subtitle2">{data.message}</Typography>
    }

    return (
        <ListItem
            alignItems="center"
            divider
            sx={{bgcolor: data.isRead ? "inherit" : "white"}}
            secondaryAction={
            <>
                <Tooltip
                    title={data.isRead ? "Mark Unread" : "Mark Read"}
                >
                    <IconButton
                        size="small"
                        edge="end"
                        onClick={() => handleRead(data.notificationId)}
                    >
                        <MarkEmailReadIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        size="small"
                        edge="end"
                        onClick={() => handleDelete(data.notificationId)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>
            }
        >
            <ListItemIcon>
                {data.isRead ? null : <CircleIcon fontSize="small" color="secondary" />}
            </ListItemIcon>
            <ListItemText
                sx={{pr: 5}}
                primary={message}
                disableTypography
            />
        </ListItem>
    )
}

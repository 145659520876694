import {useState, useEffect, useMemo} from "react";
import {AdminCompetitionDetail, RequestStatus, RoundPhase} from "../../data-types";
import {parseISO} from "date-fns";
import {DataAccess} from "../../util";

interface RoundPhaseDateRange {
    roundId: number;
    roundNumber: number;
    phase: RoundPhase;
    start: Date;
    end: Date;
}

export const useCompetitionDetail = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [competitionDetail, setCompetitionDetail] = useState<AdminCompetitionDetail | null>(null);

    useEffect(() => {

        const controller = new AbortController();
        const getCompetitionDetail = async () => {
            if (competitionId) {
                const r = await DataAccess.get(`/api/competition/${competitionId}/detailAdmin.json`, {signal: controller.signal});
                setCompetitionDetail(r.competitionAdminDetail);
            }
        }

        getCompetitionDetail()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return ()=> {
            controller.abort();
        }

    }, [competitionId]);

    // this marshalls competitionDetail.roundMeta from array of RoundMeta, which is possibly unsorted,
    // to an array of each phase to make date range comparison and overlap detection easier.
    // see useRoundEditOngoing
    const roundPhaseDateRanges = useMemo((): RoundPhaseDateRange[] => {
        const rounds = competitionDetail?.roundMeta;
        if (rounds) {
            const unsortedPhases =  rounds.flatMap(c => {
                const response: RoundPhaseDateRange =  {
                    roundId: c.roundId,
                    roundNumber: c.roundNumber,
                    phase: "response",
                    start: parseISO(c.responseStartDate + "Z"),
                    end: parseISO(c.responseEndDate + "Z")
                };
                const review: RoundPhaseDateRange = {
                    roundId: c.roundId,
                    roundNumber: c.roundNumber,
                    phase: "review",
                    start: parseISO(c.reviewStartDate + "Z"),
                    end: parseISO(c.reviewEndDate + "Z")
                };
                const proxy: RoundPhaseDateRange | undefined = c.hasProxy ?
                    {
                        roundId: c.roundId,
                        roundNumber: c.roundNumber,
                        phase: "proxy",
                        start: parseISO(c.proxyStartDate + "Z"),
                        end: parseISO(c.proxyEndDate + "Z")
                    } : undefined;
                return proxy ? [response, review, proxy] : [response, review];
            })
            return unsortedPhases.sort((a, b) => {
                if (a.roundNumber !== b.roundNumber) {
                    return a.roundNumber - b.roundNumber;
                } else {
                    if (a.phase === "response") {
                        return -1;
                    } else if (b.phase === "response") {
                        return 1;
                    } else if (a.phase === "review") {
                        return -1;
                    } else {
                        return 1;
                    }
                }
            })
        } else {
            return [];
        }
    }, [competitionDetail]);

    return {
        requestStatus: requestStatus,
        competitionDetail: competitionDetail,
        roundPhaseDateRanges: roundPhaseDateRanges
    }
}
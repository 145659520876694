import React from "react";
import {Modal, Grid2 as Grid, Typography, Divider} from "@mui/material";
import {ModalBox} from "../modal-box";
import {UserProfileForm} from "../user-profile-form";
import {useProfileEdit} from "../../hooks";
import {UserProfileState} from "../../data-types";
import {DataValidation, formatError} from "../../util";
import {toast} from "react-toastify";

interface ProfileEditModalProps {
    open: boolean;
    onClose: () => void;
    user: UserProfileState;
    handleCancel: () => void;
    handleSave: (u: UserProfileState) => void;
    residentOnly: boolean;
    birthdayOnly: boolean;
    selectedCompetition: number;
}

export function ProfileEditModal({open, onClose, user, selectedCompetition, handleCancel, handleSave, residentOnly, birthdayOnly}: ProfileEditModalProps) {

    const {
        handleChange,
        editedProfileState,
        submitProfileEdits,
        handlePhoneChange,
        handleHighSchoolChange,
        handleSelectChange,
        handleDateChange,
        errorHighlight,
        editedPhoneState,
        handleSetModified
    } = useProfileEdit(user);

    const submit = () => {
        if (birthdayOnly && editedProfileState.dateOfBirth === null) {
            toast.error("Please complete the birthday field.");
            return;
        }
        if (residentOnly && (editedProfileState.usResident === null || editedProfileState.dateOfBirth === null)) {
            toast.error("Please complete all fields.");
            return;
        }
        if (!residentOnly && !birthdayOnly && !DataValidation.isUserProfileComplete(editedProfileState, editedPhoneState)) {
            toast.error("Please complete all fields.");
            return;
        }
        submitProfileEdits(residentOnly, birthdayOnly, selectedCompetition)
            .then(_ => {
                handleSave({
                        ...editedProfileState,
                        phoneCountryCode: editedPhoneState.phoneCountryCode,
                        phone: editedPhoneState.phone.split(`+${editedPhoneState.phoneCountryCode}`)[1]
                    });
                toast.success("Profile saved");
            })
            .catch(e => {
                console.log(formatError(e));
                toast.error(formatError(e));
            })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            disableEnforceFocus
        >
            <ModalBox>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6" sx={{mb: 1}}>
                            Complete Your Profile
                        </Typography>
                        <Typography variant="subtitle2">
                            In order to enroll in this event, you must complete and save the following information:
                        </Typography>
                        <Divider sx={{my: 2}} />
                    </Grid>
                </Grid>
                <UserProfileForm
                    currentProfileState={user}
                    editedProfileState={editedProfileState}
                    handleChange={handleChange}
                    handlePhoneChange={handlePhoneChange}
                    handleDateChange={handleDateChange}
                    handleHighSchoolChange={handleHighSchoolChange}
                    isModified={true}
                    residentOnly={residentOnly}
                    requiredOnly={true}
                    birthdayOnly={birthdayOnly}
                    submit={submit}
                    handleCancel={handleCancel}
                    handleSelectChange={handleSelectChange}
                    errorHighlight={errorHighlight}
                    editedPhoneState={editedPhoneState}
                    handleSetModified={handleSetModified}
                />
            </ModalBox>
        </Modal>
    )
}
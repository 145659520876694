import React, {useState, useCallback, useEffect, useContext} from "react";
import {Box, Button, Container, Grid2 as Grid, Paper, Typography} from "@mui/material";
import {useRecord, useVideoResponseConfig, useVideoResponseSave} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorComponent, LoadingSpinner, Webcam,
    RecordingHelpModal, SubmitResponseModal, ViewVideoModal,
    ConfirmActionModal, VideoUploadingModal,
    // FileDropUpload
} from "../../components";
import {ResponseMeta} from "./ResponseMeta";
import {ResponseContent} from "./ResponseContent";
import {
    VideoSource,
    RequestStatus,
    TranscriptRequestStatus,
    ModerationResult,
    // FileUploadConfig,
    RecordingConfig
} from "../../data-types";
import AlarmIcon from "@mui/icons-material/Alarm";
import {DataFormatting, formatError} from "../../util";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import VideocamIcon from '@mui/icons-material/Videocam';
// import UploadIcon from '@mui/icons-material/Upload';
import {toast} from "react-toastify";
import {UserContext, BrandingContext} from "../../context";

export function VideoResponse() {
    const {competitionId} = useParams();
    const navigate = useNavigate();
    const {setLogoUrl} = useContext(BrandingContext);
    const {user} = useContext(UserContext);
    const {
        time,
        startRecording,
        stopRecording,
        resetRecording,
        isRecording,
        isDataAvailable,
        recordingData
    } = useRecord(60);

    // configures page with all required responses for this round
    const {
        responseRequestStatus,
        metaRequestStatus,
        responseContent,
        responseMeta,
        selectedResponse,
        handleSwitchPrompt,
        handleSaveSuccess,
        handleModerationChange
    } = useVideoResponseConfig(competitionId);
    const {saveResponse, submitResponses, uploadProgress, createTranscript} = useVideoResponseSave();
    // const [recordToggle, _setRecordToggle] = useState<boolean>(true);
    const [videoSource, setVideoSource] = useState<VideoSource>({src: "", type: ""});
    // const [videoUploadConfig, setVideoUploadConfig] = useState<FileUploadConfig | null>(null);

    const brandingCallback = useCallback(() => {
        if (user && user.institutionLogo) {
            setLogoUrl(user.institutionLogo);
        } else {
            setLogoUrl(null);
        }
    }, [user, setLogoUrl]);

    // change the navbar logo back to what it was before loading video response page
    useEffect(() => {
        return brandingCallback;
    }, [brandingCallback]);

    const setRecordingSource = () => {
        if (videoSource.src !== "") {
            URL.revokeObjectURL(videoSource.src);
        }
        const mimeType = DataFormatting.getRecordingMimeType();
        const video = new Blob(recordingData.current, {type: mimeType});
        const url = URL.createObjectURL(video);
        setVideoSource({src: url, type: mimeType.split(";")[0]});
    };

    // const setUploadingSource = () => {
    //     URL.revokeObjectURL(videoSource.src);
    //     const url = URL.createObjectURL(videoUploadConfig!.file);
    //     setVideoSource({src: url, type: videoUploadConfig!.mimeType});
    // }

    const submitResponsesAction = () => {
        submitResponses(competitionId)
            .then(_ => {
                toast.success("Responses Submitted Successfully");
                navigate("/myEvents");
            })
            .catch(e => {
                setConfirmSubmitOpen(false);
                toast.error(formatError(e));
            })
    }

    const saveAction = () => {
        setSubmitModal(false);
        setUploadingModalOpen(true);
        const data: RecordingConfig = {
                recordingData: recordingData.current,
                isRecording: false,
                isStopped: true,
                time: time,
                mimeType: DataFormatting.getRecordingMimeType(),
        };

        // const data: RecordingConfig | FileUploadConfig = (recordToggle) ?
        //     {
        //         recordingData: recordingData.current,
        //         isRecording: false,
        //         isStopped: true,
        //         time: time,
        //         mimeType: DataFormatting.getRecordingMimeType(),
        //     } :
        //     videoUploadConfig!;

        saveResponse(selectedResponse, data)
            .then(_ => {
                setUploadStatus("complete");
                setVideoSource({src: "", type: ""});
                handleSaveSuccess(selectedResponse, videoSource);
                toast.success("Video Response Saved Successfully");
                resetRecording();
            })
            .then(_ => {
                createTranscript(selectedResponse)
                    .then(r => {
                        if (r && r.flagged) {
                            setTranscriptStatus("moderation");
                            setModerationResult(r);
                            handleModerationChange(selectedResponse, true);
                        } else {
                            setTranscriptStatus("complete");
                            setModerationResult({flagged: false, categories: {}});
                            handleModerationChange(selectedResponse, false);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        toast.error("Transcript could not be created on upload.");
                        setTranscriptStatus("error");
                    })
            })
            .catch(e => {
                console.log(e);
                toast.error("There was a problem saving your response, please try again.");
                setUploadingModalOpen(false);
            });
    }

    const openConfirmSubmit = () => {
        setConfirmSubmitOpen(true);
    }

    // modal toggles
    const [submitOpen, setSubmitModal] = useState<boolean>(false);
    const [recordingHelpOpen, setRecordingHelpOpen] = useState<boolean>(false);
    const [viewVideoOpen, setViewVideoOpen] = useState<boolean>(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState<boolean>(false);

    const [uploadStatus, setUploadStatus] = useState<RequestStatus>("loading");
    const [uploadingModalOpen, setUploadingModalOpen] = useState<boolean>(false);

    const [transcriptStatus, setTranscriptStatus] = useState<TranscriptRequestStatus>("loading");
    const [moderationResult, setModerationResult] = useState<ModerationResult>({flagged: false, categories: {}});

    const handleUploadModalClose = useCallback(() => {
        setUploadingModalOpen(false);
        setUploadStatus("loading");
        setTranscriptStatus("loading");
    }, []);

    const retryTranscript = () => {
        setTranscriptStatus("loading");
        createTranscript(selectedResponse)
            .then(r => {
                if (r && r.flagged) {
                    setTranscriptStatus("moderation");
                    setModerationResult(r);
                    handleModerationChange(selectedResponse, true);
                } else {
                    setTranscriptStatus("complete");
                    setModerationResult({flagged: false, categories: {}});
                    handleModerationChange(selectedResponse, false);
                }
            })
            .catch(e => {
                console.log(e);
                toast.error("Transcript could not be created on upload.");
            })
    }

    if (metaRequestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (metaRequestStatus === "error") {
        return <ErrorComponent />;
    } else {

        const content = responseContent[selectedResponse];

        return (
            <Container sx={{mb: 10}}>
                <ResponseMeta
                    selectedResponse={selectedResponse}
                    responseMeta={responseMeta}
                    handleSwitchPrompt={handleSwitchPrompt}
                    disableAll={isRecording}
                    resetRecording={resetRecording}
                    submit={openConfirmSubmit}
                />
                <Grid container spacing={1}>
                    <ResponseContent
                        responseRequestStatus={responseRequestStatus}
                        content={content}
                    />
                    {responseRequestStatus === "loading" || responseRequestStatus === "error" ?
                        null :
                        <Grid container size={{xs: 12, sm: 12, md: 6}}>
                            <Paper elevation={0} sx={{p: 2, width: "100%"}}>
                                <Grid size={{xs: 12}}>
                                    <Box component="div" sx={{display: "flex", alignItems: "center", justifyContent: "space-between", m: 2}}>
                                        <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                            {(content?.videoResponse) ?
                                                <CheckCircleIcon color="primary" /> :
                                                null
                                            }
                                            <Typography variant="h6" sx={{ml: 2}}>
                                                Your Response
                                            </Typography>
                                        </Box>
                                        {(content?.videoResponse) ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setViewVideoOpen(true);
                                                }}
                                            >
                                                See Saved Response
                                            </Button> :
                                            null
                                        }
                                    </Box>
                                </Grid>
                                <Grid size={{xs: 12}}>
                                    {/*{recordToggle ?*/}
                                    {/*    <>*/}
                                            <Box component="div" sx={{justifyContent: "center", alignItems: "center", display: "flex", mb: 2}}>
                                                <AlarmIcon sx={{mr: 1}} />
                                                <Typography display="inline" variant="subtitle2" sx={{mr: 1}}>
                                                    Time Remaining:
                                                </Typography>
                                                <Typography display="inline" variant="subtitle2" color={(time <= 10) ? "secondary" : "" }>
                                                    {DataFormatting.secondsToTimeString(time)}
                                                </Typography>
                                            </Box>
                                            <Webcam
                                                startRecording={startRecording}
                                                stopRecording={stopRecording}
                                                resetRecording={resetRecording}
                                                isRecording={isRecording}
                                                isDataAvailable={isDataAvailable}
                                            />
                                    {/* </> */}
                                    {/*  :  <Box component="div" sx={{my: 7}}>*/}
                                    {/*        <FileDropUpload*/}
                                    {/*            maxFileSize={13000000}*/}
                                    {/*            acceptedFileTypes={{"VIDEO/MP4": [".mp4"]}}*/}
                                    {/*            uploadConfig={videoUploadConfig}*/}
                                    {/*            setUploadConfig={setVideoUploadConfig}*/}
                                    {/*            maxVideoDuration={60}*/}
                                    {/*        />*/}
                                    {/*    </Box>*/}
                                    {/*}*/}
                                </Grid>
                                <Grid container spacing={2} size={{xs: 12}} sx={{mt: 3}}>
                                    {/*<Grid size={{xs: 12}}>*/}
                                    {/*    <Button*/}
                                    {/*        color="darkPrimary"*/}
                                    {/*        fullWidth*/}
                                    {/*        disabled={isRecording}*/}
                                    {/*        startIcon={recordToggle ? <UploadIcon /> : <VideocamIcon />}*/}
                                    {/*        onClick={() => setRecordToggle(prev => !prev)}*/}
                                    {/*    >*/}
                                    {/*        {recordToggle ? "Upload Response" : "Record Response"}*/}
                                    {/*    </Button>*/}
                                    {/*</Grid>*/}
                                    <Grid size={{xs: 12}}>
                                        {/*{recordToggle ?*/}
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                fullWidth
                                                disabled={isRecording || !isDataAvailable}
                                                startIcon={<CheckCircleIcon />}
                                                onClick={() => {
                                                    setRecordingSource();
                                                    setSubmitModal(true)
                                                }}
                                            >
                                                Review and Save
                                            </Button>
                                        {/*    : <Button*/}
                                        {/*        color="primary"*/}
                                        {/*        variant="contained"*/}
                                        {/*        fullWidth*/}
                                        {/*        disabled={videoUploadConfig === null}*/}
                                        {/*        startIcon={<CheckCircleIcon />}*/}
                                        {/*        onClick={() => {*/}
                                        {/*            setUploadingSource();*/}
                                        {/*            setSubmitModal(true);*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        Review and Save*/}
                                        {/*    </Button>*/}
                                        {/*}*/}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    }
                </Grid>
                <SubmitResponseModal
                    videoSource={videoSource}
                    open={submitOpen}
                    setOpen={setSubmitModal}
                    action={saveAction}
                    modalText="This will overwrite any previous submissions for this prompt."
                    loading={false}
                />
                <RecordingHelpModal open={recordingHelpOpen} setOpen={setRecordingHelpOpen} action={() => {}} />
                <ViewVideoModal
                    videoOptions={(content) ? content.videoResponse : null}
                    open={viewVideoOpen}
                    setOpen={setViewVideoOpen}
                    action={() => {}}
                />
                <ConfirmActionModal
                    open={confirmSubmitOpen}
                    setOpen={setConfirmSubmitOpen}
                    action={submitResponsesAction}
                    confirmActionText="Submit"
                >
                    <Grid container>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Submit Responses
                            </Typography>
                            <Typography variant="subtitle2">
                                Are you sure you want to submit responses?  You will not be able to change your submissions once you confirm.
                            </Typography>
                            {responseMeta.some(c => c.flagged) ?
                                <Typography variant="subtitle2">
                                    NOTE:  You are attempting to submit responses when one or more has been flagged for content moderation.  If you proceed, an administrator will further review these flagged responses and may take action against your score or continued participation in this event.
                                </Typography> :
                                null
                            }
                        </Grid>
                    </Grid>
                </ConfirmActionModal>
                <VideoUploadingModal
                    open={uploadingModalOpen}
                    handleClose={handleUploadModalClose}
                    uploadStatus={uploadStatus}
                    videoUploadProgress={uploadProgress}
                    transcriptStatus={transcriptStatus}
                    retryTranscript={retryTranscript}
                    link={`/responses/${selectedResponse}`}
                    moderationResult={moderationResult}
                />
            </Container>
        )
    }
}
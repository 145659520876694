import React from "react";
import {Grid2 as Grid, Paper, Typography, Box, SxProps} from "@mui/material";
import {RoundMeta, AdminCompetitionMeta} from "../../data-types/model";
import {parseISO, format, isAfter, isBefore} from "date-fns";
import {SchedulingError} from "./SchedulingError";

interface FlatRoundCalendarProps {
    competitionMeta: AdminCompetitionMeta;
    roundMeta: RoundMeta;
    thisRoundStart: Date;
    thisRoundEnd: Date;
    editRoundNumber?: number; // when doing edit, filter out this round number so as not to conflict with itself
}

interface RoundStartEnd {
    start: Date;
    end: Date;
    roundNumber: number;
}

interface RoundLabel {
    position: number;
    label: string;
    sublabels: string[];
}

interface A {
    p: number;
    id: number;
}

function toGradient(start: Date, end: Date, rounds: RoundStartEnd[]): {gradient: string, conflict: boolean} {
    if (rounds.length === 0) {
        return {
            gradient: "linear-gradient(to right, #A3C49F 0% 100%)",
            conflict: false
        }
    }

    const startTime = start.getTime();
    const diff = end.getTime() - startTime;
    let conflict = false;

    const As: A[] = [];
    //  rounds to A
    rounds.forEach(r => {
        const s = {p: ((r.start.getTime() - startTime) / diff) * 100, id: r.roundNumber};
        const e = {p: ((r.end.getTime() - startTime) / diff) * 100, id: r.roundNumber};
        if (s.p >= 0 && e.p >= 0) {
            As.push(s, e);
        }
    })

    As.sort((a, b) => a.p - b.p);

    let gradient = "linear-gradient(to right, ";
    let stack: A[] = [];
    let segments: string[] = [];
    let left = 0;
    As.forEach(A => {
        const segment: string = ` ${left}% ${A.p}% `;
        let color: string = "";
        left = A.p;
        if (stack.length === 0) {
            color = "#A3C49F";
            stack.push(A);
        } else if (stack.length === 1) {
            color = (stack[0].id === -1) ? "blue" : "#28871D";
            if (stack[0].id === A.id) {
                stack.pop();
            } else {
                stack.push(A);
            }
        } else if (stack.length === 2) {
            color = "#EB0052";
            stack = [...stack].filter(s => s.id !== A.id);
            conflict = true;
        } else {
            console.log("error");
        }

        segments.push(color + segment);
    })

    segments.push(`#A3C49F ${left}% 100%`);
    return {
        gradient: gradient + segments.join(', ') + ")",
        conflict: conflict
    }
}

export function FlatRoundCalendar({competitionMeta, roundMeta, thisRoundStart, thisRoundEnd, editRoundNumber}: FlatRoundCalendarProps) {

    const now = new Date();

    const {visibilityDate: visibilityDateString, endDate: endDateString} = competitionMeta;
    const visibilityDate = parseISO(visibilityDateString + "Z");
    const endDate = parseISO(endDateString + "Z");
    const visibilityValue = visibilityDate.getTime();
    const endValue = endDate.getTime();
    const diff = endValue - visibilityValue;
    let outOfBoundsConflict: string = "";

    // map roundMeta to array of start-end times based on whether round has proxy or not
    let roundStartsAndEnds: RoundStartEnd[] = roundMeta.map(c => {
        return {
            start: parseISO(c.responseStartDate + "Z"),
            end: c.hasProxy ? parseISO(c.proxyEndDate + "Z") : parseISO(c.reviewEndDate + "Z"),
            roundNumber: c.roundNumber
        }
    });


    if (editRoundNumber) {
        roundStartsAndEnds = roundStartsAndEnds.filter(c => c.roundNumber !== editRoundNumber);
    }

    if (isBefore(thisRoundStart, visibilityDate)) {
        outOfBoundsConflict = "The round must start after the Event Start date and before the Event End date.";
    } else if (isBefore(thisRoundEnd, visibilityDate)) {
        outOfBoundsConflict = "The round must end after the Event Start date and before the Event End date.";
    } else if (isAfter(thisRoundEnd, endDate)) {
        outOfBoundsConflict = "The round must end after the Event Start date and before the Event End date.";
    } else if (isBefore(thisRoundEnd, thisRoundStart)) {
        outOfBoundsConflict = "The round must end after the round starts.";
    } else {
        roundStartsAndEnds.push({start: thisRoundStart, end: thisRoundEnd, roundNumber: editRoundNumber || -1});
    }

    const {gradient: linearGradient, conflict: schedulingConflict} = toGradient(visibilityDate, endDate, roundStartsAndEnds);

    const marks: number[] = [];
    const labels: RoundLabel[] = [];

    roundStartsAndEnds.forEach(c => {
        const startPosition = ((c.start.getTime() - visibilityValue) / diff) * 100;
        const endPosition = ((c.end.getTime() - visibilityValue) / diff) * 100;
        marks.push(startPosition, endPosition);

        // divide by 5 puts the label at 20% of the interval.  I.e. if start mark = 10% and
        // end mark = 20%, then labelPosition = 12%
        const labelPosition = startPosition + ((endPosition - startPosition) / 5);
        const label: string = (editRoundNumber && editRoundNumber === c.roundNumber) ? "This Round" : c.roundNumber < 0 ? "New Round" : `Round: ${c.roundNumber}`;
        const sublabels: string[] = [`Start: ${format(c.start, "Pp")}`, `End: ${format(c.end, "Pp")}`];
        labels.push({position: labelPosition, label: label, sublabels: sublabels});
    })

    // add event start/end labels
    labels.unshift({position: 0, label: "Event Start", sublabels: [`${format(visibilityDate, "Pp")}`]});
    labels.push({position: 87, label: "Event End", sublabels: [`${format(endDate, "Pp")}`]});
    labels.sort((a, b) => a.position - b.position);

    const nowPosition = ((now.getTime() - visibilityValue) / diff) * 100;

    return (
        <Grid container sx={{mb: 4}}>
            <Grid size={{xs: 12}} sx={{display: {xs: "none", sm: "inherit"}, mb: 14, mt: 14}}>
                {/* wrapper */}
                <Box component="div" sx={{position: "relative", height: 10, width: "100%"}}>
                    {/* rail */}
                    <Box
                        component="div"
                        sx={{
                            position: "absolute",
                            left: "50%",
                            top: "60%",
                            transform: "translate(-50%, -50%)",
                            height: 8,
                            width: "90%",
                            background: linearGradient
                        }}
                    >
                        {/*  First mark at event visibility date  */}
                        <Box
                            component="div"
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "0%",
                                transform: "translateY(-50%)",
                                margin: "auto 0",
                                height: "2em",
                                width: "0.4em",
                                backgroundColor: "#888",
                                border: "1px solid #000",
                                borderRadius: "2px"
                            }}
                        />
                        {marks.map((c, i) =>
                            <Box
                                component="div"
                                key={i}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: `${c}%`,
                                    transform: "translateY(-50%)",
                                    margin: "auto 0",
                                    height: "2em",
                                    width: "0.4em",
                                    backgroundColor: "#888",
                                    border: "1px solid #000",
                                    borderRadius: "2px"
                                }}
                            />
                        )}
                        {/* Now mark and label ONLY if now is after eventStart*/}
                        {nowPosition > 0 ?
                            <>
                                <Box
                                    component="div"
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: `${nowPosition}%`,
                                        transform: "translateY(-50%)",
                                        margin: "auto 0",
                                        height: "2em",
                                        width: "0.4em",
                                        backgroundColor: "yellow",
                                        border: "1px solid #000",
                                        borderRadius: "2px"
                                    }}
                                />
                                <Box
                                    component={Paper}
                                    elevation={1}
                                    sx={{
                                        position: "absolute",
                                        left: `${nowPosition - 4}%`,
                                        textAlign: "center",
                                        border: "1px solid #d5d5d5",
                                        borderRadius: 1,
                                        p: "5px",
                                        width: "fit-content",
                                        minWidth: "5em",
                                        cursor: "pointer",
                                        "&:hover": {
                                            zIndex: 3
                                        },
                                        bottom: "unset",
                                        top: 40
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        Now
                                    </Typography>
                                </Box>
                            </> :
                            null
                        }

                        {labels.map((c, i) => {
                            const isBottom = i % 2 === 0;
                            const labelPosition: SxProps = isBottom ? {bottom: "unset", top: 40} : {bottom: 40};
                            const pointerPosition: SxProps = isBottom ? {} : {top: -40};
                            const isEnd = c.label === "Event End";
                            return (
                                <Box component="div" key={i}>
                                    <Box
                                        component={Paper}
                                        elevation={1}
                                        sx={{
                                            position: "absolute",
                                            left: `${c.position}%`,
                                            textAlign: "center",
                                            border: "1px solid #d5d5d5",
                                            borderRadius: 1,
                                            p: "5px",
                                            width: "fit-content",
                                            minWidth: "9em",
                                            cursor: "pointer",
                                            "&:hover": {
                                                zIndex: 3
                                            },
                                            ...labelPosition
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            {c.label}
                                        </Typography>
                                        {c.sublabels.map((cc, ii) =>
                                            <Typography variant="subtitle2" key={ii} sx={{fontSize: 10}}>
                                                {cc}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box
                                        component="div"
                                        sx={{
                                            borderLeft: "1px solid #888",
                                            position: "absolute",
                                            left: isEnd ? "100%" : `${c.position}%`,
                                            height: 40,
                                            width: 0,
                                            ...pointerPosition
                                        }}
                                    />
                                </Box>
                            )
                        })}
                        {/*  Last mark at event end date  */}
                        <Box component="div" sx={{
                            position: "absolute",
                            top: "50%",
                            left: "100%",
                            transform: "translateY(-50%)",
                            margin: "auto 0",
                            height: "2em",
                            width: "0.4em",
                            backgroundColor: "#888",
                            border: "1px solid #000",
                            borderRadius: "2px"
                        }} />
                    </Box>
                </Box>
            </Grid>
            {schedulingConflict ?
                <SchedulingError message="This round is overlapping with one or more rounds." /> :
                null
            }
            {outOfBoundsConflict ?
                <SchedulingError message={outOfBoundsConflict} /> :
                null
            }
        </Grid>
    )
}
import React, {useState, useCallback} from "react";
import {Container, Typography, Grid2 as Grid, Paper, Button} from "@mui/material";
import {LoadingSpinner, ErrorComponent} from "../../components";
import {useNavigate, useParams} from "react-router-dom";
import {DataAccess, formatError} from '../../util';
import {FlatRoundCalendar} from "../admin-create-round/FlatRoundCalendar";
import {parseISO, isAfter, addMilliseconds} from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {useCompetitionDetail} from "../../hooks";
import {TimeWaringModal} from "./TimeWaringModal";
import {AdminCompetitionMeta, RoundTimeDelta} from "../../data-types";
import {toast} from "react-toastify";

export function AdminEditOngoingRound() {

    const [roundTimeDelta, setRoundTimeDelta] = useState<RoundTimeDelta>({
        responseEndTimeDelta: 0,
        reviewStartTimeDelta: 0,
        reviewEndTimeDelta: 0,
        proxyStartTimeDelta: 0,
        proxyEndTimeDelta: 0,
        laterRoundsDelta: 0
    });

    const navigate = useNavigate();
    const {roundId, competitionId} = useParams();
    const {competitionDetail, requestStatus: competitionRequestStatus, roundPhaseDateRanges} = useCompetitionDetail(competitionId);
    const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
    const [timeDelta, setTimeDelta]  = useState<number>(0);
    const [selectedDelta, setSelectedDelta] = useState<string>("");

    const handleWarningModalClose = () => {
        const round = parseInt(roundId ?? "");
        // need to reset the RoundPhaseDateRange phase that was modified in handleDateChange case statement
        switch (selectedDelta) {
            case "responseEndTimeDelta": {
                const responsePhase = roundPhaseDateRanges.find(c => c.roundId === round && c.phase === "response")!;
                responsePhase.end = addMilliseconds(responsePhase.end, -1 * timeDelta);
                break;
            }
            case "reviewStartTimeDelta": {
                const reviewPhase = roundPhaseDateRanges.find(c => c.roundId === round && c.phase === "review")!;
                reviewPhase.start = addMilliseconds(reviewPhase.start, -1 * timeDelta);
                break;
            }
            case "reviewEndTimeDelta": {
                const reviewPhase = roundPhaseDateRanges.find(c => c.roundId === round && c.phase === "review")!;
                reviewPhase.end = addMilliseconds(reviewPhase.end, -1 * timeDelta);
                break;
            }
            case "proxyStartTimeDelta": {
                const proxyPhase = roundPhaseDateRanges.find(c => c.roundId === round && c.phase === "proxy");
                if (proxyPhase) {
                    proxyPhase.start = addMilliseconds(proxyPhase.start, -1 * timeDelta);
                }
                break;
            }
            case "proxyEndTimeDelta": {
                const proxyPhase = roundPhaseDateRanges.find(c => c.roundId === round && c.phase === "proxy");
                if (proxyPhase) {
                    proxyPhase.end = addMilliseconds(proxyPhase.end, -1 * timeDelta);
                }
                break;
            }
        }

        setTimeDelta(0);
        setSelectedDelta("");
        setWarningModalOpen(false);
    }

    const handleDateChange = useCallback((dateKey: string, value: Date | null) => {
        const round = parseInt(roundId ?? "");
        if (value === null || isNaN(round)) {
            return;
        }
        const now = new Date();
        const upcomingPhases = roundPhaseDateRanges.filter(c => c.end > now);
        // calculate the time delta for selected phase and add it to the edited phase
        let timeDelta = 0;
        switch (dateKey) {
            case "responseEndTimeDelta": {
                const responsePhase = upcomingPhases.find(c => c.roundId === round && c.phase === "response")!;
                timeDelta = value.getTime() - responsePhase.end.getTime();
                responsePhase.end = addMilliseconds(responsePhase.end, timeDelta);
                break;
            }
            case "reviewStartTimeDelta": {
                const reviewPhase = upcomingPhases.find(c => c.roundId === round && c.phase === "review")!;
                timeDelta = value.getTime() - reviewPhase.start.getTime();
                reviewPhase.start = addMilliseconds(reviewPhase.start, timeDelta);
                break;
            }
            case "reviewEndTimeDelta": {
                const reviewPhase = upcomingPhases.find(c => c.roundId === round && c.phase === "review")!;
                timeDelta = value.getTime() - reviewPhase.end.getTime();
                reviewPhase.end = addMilliseconds(reviewPhase.end, timeDelta);
                break;
            }
            case "proxyStartTimeDelta": {
                const proxyPhase = upcomingPhases.find(c => c.roundId === round && c.phase === "proxy");
                if (proxyPhase) {
                    timeDelta = value.getTime() - proxyPhase.start.getTime();
                    proxyPhase.start = addMilliseconds(proxyPhase.start, timeDelta);
                } else {
                    return; // no op
                }
                break;
            }
            case "proxyEndTimeDelta": {
                const proxyPhase = upcomingPhases.find(c => c.roundId === round && c.phase === "proxy");
                if (proxyPhase) {
                    timeDelta = value.getTime() - proxyPhase.end.getTime();
                    proxyPhase.end = addMilliseconds(proxyPhase.end, timeDelta);
                } else {
                    return;
                }
                break;
            }
        }

        // upcoming phase should be modified with updated timeDelta value for affected date.
        // check for conflicts:  if detected, render warning modal
        const hasConflict = upcomingPhases.reduce((a, c, i, phases) => {
            // start after end is a conflict
            if (c.start > c.end) {
                return a || true;
            }
            // else, check if previous element overlaps
            if (i > 0) {
                const prev = phases[i - 1];
                return a || (prev.start <= c.end && prev.end >= c.start);
            } else {
                return a || false;
            }
        }, false);

        // finally, check if event end date is before any date
        const endDate = parseISO(competitionDetail!.competitionMeta.endDate + "Z");
        const pastEndDate = upcomingPhases.some(c => c.end >= endDate || c.start >= endDate);

        // if conflict exists, ask for user confirmation to adjust delta
        if (hasConflict || pastEndDate) {
            setWarningModalOpen(true);
            setTimeDelta(timeDelta);
            setSelectedDelta(dateKey);
        } else {
            // else, set roundTimeDelta state accordingly
            setRoundTimeDelta(prev => {
                return {
                    ...prev,
                    [dateKey]: timeDelta
                }
            })
        }

    }, [roundPhaseDateRanges, roundId, competitionDetail]);

    const submitChanges = () => {
        DataAccess.put(`/api/round/${roundId}/editOngoing.json`, {data: {timeDeltas: roundTimeDelta}})
            .then(_ => {
                toast.success("Round updated successfully.");
                navigate(`/admin/events/detail/${competitionId}`);
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    const handleConfirm = () => {
        // update only values after the modified phase
        switch (selectedDelta) {
            case "responseEndTimeDelta": {
                setRoundTimeDelta(prev => {
                    return {
                        responseEndTimeDelta: prev.responseEndTimeDelta + timeDelta,
                        reviewStartTimeDelta: prev.reviewStartTimeDelta + timeDelta,
                        reviewEndTimeDelta: prev.reviewEndTimeDelta + timeDelta,
                        proxyStartTimeDelta: prev.proxyStartTimeDelta + timeDelta,
                        proxyEndTimeDelta: prev.proxyEndTimeDelta + timeDelta,
                        laterRoundsDelta: prev.laterRoundsDelta + timeDelta
                    }
                });
                break;
            }
            case "reviewStartTimeDelta": {
                setRoundTimeDelta(prev => {
                    return {
                        ...prev,
                        reviewStartTimeDelta: prev.reviewStartTimeDelta + timeDelta,
                        reviewEndTimeDelta: prev.reviewEndTimeDelta + timeDelta,
                        proxyStartTimeDelta: prev.proxyStartTimeDelta + timeDelta,
                        proxyEndTimeDelta: prev.proxyEndTimeDelta + timeDelta,
                        laterRoundsDelta: prev.laterRoundsDelta + timeDelta
                    }
                })
                break;
            }
            case "reviewEndTimeDelta": {
                setRoundTimeDelta(prev => {
                    return {
                        ...prev,
                        reviewEndTimeDelta: prev.reviewEndTimeDelta + timeDelta,
                        proxyStartTimeDelta: prev.proxyStartTimeDelta + timeDelta,
                        proxyEndTimeDelta: prev.proxyEndTimeDelta + timeDelta,
                        laterRoundsDelta: prev.laterRoundsDelta + timeDelta
                    }
                })
                break;
            }
            case "proxyStartTimeDelta": {
                setRoundTimeDelta(prev => {
                    return {
                        ...prev,
                        proxyStartTimeDelta: prev.proxyStartTimeDelta + timeDelta,
                        proxyEndTimeDelta: prev.proxyEndTimeDelta + timeDelta,
                        laterRoundsDelta: prev.laterRoundsDelta + timeDelta
                    }
                })
                break;
            }
            case "proxyEndTimeDelta": {
                setRoundTimeDelta(prev => {
                    return {
                        ...prev,
                        proxyEndTimeDelta: prev.proxyEndTimeDelta + timeDelta,
                        laterRoundsDelta: prev.laterRoundsDelta + timeDelta
                    }
                })
                break;
            }
        }
        setTimeDelta(0);
        setSelectedDelta("");
        setWarningModalOpen(false);
    }

    if (competitionRequestStatus === "loading"){
        return <LoadingSpinner />;
    } else if (competitionRequestStatus === "error"){
        return <ErrorComponent />;
    } else {
        const thisRound = competitionDetail?.roundMeta.find(c => c.roundId === parseInt(roundId!));
        if (!thisRound) {
            return <ErrorComponent />;
        } else {

            const now = new Date();
            const thisRoundResponseStart = parseISO(thisRound.responseStartDate + "Z");
            const thisRoundResponseEnd = addMilliseconds(parseISO(thisRound.responseEndDate + "Z"), roundTimeDelta.responseEndTimeDelta);
            const thisRoundReviewStart = addMilliseconds(parseISO(thisRound.reviewStartDate + "Z"), roundTimeDelta.reviewStartTimeDelta);
            const thisRoundReviewEnd = addMilliseconds(parseISO(thisRound.reviewEndDate + "Z"), roundTimeDelta.reviewEndTimeDelta);
            const thisRoundProxyStart = thisRound.hasProxy ? addMilliseconds(parseISO(thisRound.proxyStartDate + "Z"), roundTimeDelta.proxyStartTimeDelta) : null;
            const thisRoundProxyEnd = thisRound.hasProxy ? addMilliseconds(parseISO(thisRound.proxyEndDate + "Z"), roundTimeDelta.proxyEndTimeDelta) : null;
            const thisRoundNumber = thisRound.roundNumber;

            // console.log(`thisRoundResponseEnd ${thisRoundResponseEnd}`);

            const updatedRoundMeta = competitionDetail?.roundMeta.map(c => {
                return {
                    ...c,
                    responseStartDate: (c.roundNumber > thisRoundNumber) ? addMilliseconds(parseISO(c.responseStartDate + "Z"), roundTimeDelta.laterRoundsDelta).toISOString() : c.responseStartDate,
                    responseEndDate: (c.roundNumber > thisRoundNumber) ? addMilliseconds(parseISO(c.responseEndDate + "Z"), roundTimeDelta.laterRoundsDelta).toISOString() : c.responseEndDate,
                    reviewStartDate: (c.roundNumber > thisRoundNumber) ? addMilliseconds(parseISO(c.reviewStartDate + "Z"), roundTimeDelta.laterRoundsDelta).toISOString() : c.reviewStartDate,
                    reviewEndDate: (c.roundNumber > thisRoundNumber) ? addMilliseconds(parseISO(c.reviewEndDate + "Z"), roundTimeDelta.laterRoundsDelta).toISOString() : c.reviewEndDate,
                    proxyStartDate: (c.roundNumber > thisRoundNumber && c.hasProxy) ? addMilliseconds(parseISO(c.proxyStartDate + "Z"), roundTimeDelta.laterRoundsDelta).toISOString() : c.proxyStartDate,
                    proxyEndDate: (c.roundNumber > thisRoundNumber && c.hasProxy) ? addMilliseconds(parseISO(c.proxyEndDate + "Z"), roundTimeDelta.laterRoundsDelta).toISOString() : c.proxyEndDate
                }
            });

            const updatedCompetitionMeta: AdminCompetitionMeta | undefined = competitionDetail?.competitionMeta ? {...competitionDetail.competitionMeta} : undefined;
            if (updatedCompetitionMeta) {
                updatedCompetitionMeta.endDate = addMilliseconds(parseISO(updatedCompetitionMeta.endDate + "Z"), roundTimeDelta.laterRoundsDelta).toISOString();
            }

            return (
                <Container sx={{mb: 10}}>
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h4">
                                Edit Ongoing Round
                            </Typography>
                        </Grid>
                        <Paper component={Grid} elevation={0} spacing={2} container size={{xs: 12}} alignItems="center" justifyContent="space-between" sx={{p: 3}}>
                            <Typography variant="subtitle2">
                                You are attempting to change the dates for a round that is already in progress.  Some edits may require moving the starts and ends of later rounds, or changing the end date of the event.  You will be prompted before these changes are finalized.
                            </Typography>
                        </Paper>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6">
                                {`Round ${thisRoundNumber}`}
                            </Typography>
                        </Grid>
                        {updatedCompetitionMeta && updatedRoundMeta ?
                            <Grid size={{xs: 12}}>
                                <FlatRoundCalendar
                                    competitionMeta={updatedCompetitionMeta}
                                    roundMeta={updatedRoundMeta}
                                    thisRoundStart={thisRoundResponseStart}
                                    thisRoundEnd={thisRoundProxyEnd ?? thisRoundReviewEnd}
                                    editRoundNumber={thisRoundNumber}
                                />
                            </Grid> :
                            null
                        }
                    </Grid>
                    {/*  Edit date fields  */}
                    <Grid container spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {isAfter(thisRoundResponseEnd, now) ?
                                <Grid size={{xs: 12, sm: 6, md: 4}}>
                                    <DateTimePicker
                                        label="Response End Date"
                                        value={thisRoundResponseEnd}
                                        minDateTime={thisRoundResponseEnd}
                                        disablePast={true}
                                        onChange={(value) => handleDateChange("responseEndTimeDelta", value)}
                                        sx={{width: "100%"}}
                                        slotProps={{
                                            textField: {
                                                value: thisRoundResponseEnd,
                                            },
                                            field: {
                                                readOnly: true
                                            },
                                            desktopTrapFocus: {
                                                disableEnforceFocus: true
                                            }
                                        }}
                                    />
                                </Grid> :
                                null
                            }
                            {isAfter(thisRoundReviewStart, now) ?
                                <Grid size={{xs: 12, sm: 6, md: 4}}>
                                    <DateTimePicker
                                        label="Review Start Date"
                                        value={thisRoundReviewStart}
                                        minDateTime={thisRoundReviewStart}
                                        disablePast={true}
                                        onChange={(value) => handleDateChange("reviewStartTimeDelta", value)}
                                        sx={{width: "100%"}}
                                        slotProps={{
                                            textField: {
                                                value: thisRoundReviewStart
                                            },
                                            field: {
                                                readOnly: true
                                            },
                                            desktopTrapFocus: {
                                                disableEnforceFocus: true
                                            }
                                        }}
                                    />
                                </Grid> :
                                null
                            }
                            {isAfter(thisRoundReviewEnd, now) ?
                                <Grid size={{xs: 12, sm: 6, md: 4}}>
                                    <DateTimePicker
                                        label="Review End Date"
                                        value={thisRoundReviewEnd}
                                        minDateTime={thisRoundReviewEnd}
                                        disablePast={true}
                                        onChange={(value) => handleDateChange("reviewEndTimeDelta", value)}
                                        sx={{width: "100%"}}
                                        slotProps={{
                                            textField: {
                                                value: thisRoundReviewEnd
                                            },
                                            field: {
                                                readOnly: true
                                            },
                                            desktopTrapFocus: {
                                                disableEnforceFocus: true
                                            }
                                        }}
                                    />
                                </Grid> :
                                null
                            }
                            {thisRoundProxyStart && isAfter(thisRoundProxyStart, now) ?
                                <Grid size={{xs: 12, sm: 6, md: 4}}>
                                    <DateTimePicker
                                        label="Proxy Start Date"
                                        value={thisRoundProxyStart}
                                        minDateTime={thisRoundProxyStart}
                                        disablePast={true}
                                        onChange={(value) => handleDateChange("proxyStartTimeDelta", value)}
                                        sx={{width: "100%"}}
                                        slotProps={{
                                            textField: {
                                                value: thisRoundProxyStart
                                            },
                                            field: {
                                                readOnly: true
                                            },
                                            desktopTrapFocus: {
                                                disableEnforceFocus: true
                                            }
                                        }}
                                    />
                                </Grid> :
                                null
                            }
                            {thisRoundProxyEnd && isAfter(thisRoundProxyEnd, now) ?
                                <Grid size={{xs: 12, sm: 6, md: 4}}>
                                    <DateTimePicker
                                        label="Proxy End Date"
                                        value={thisRoundProxyEnd}
                                        minDateTime={thisRoundProxyEnd}
                                        disablePast={true}
                                        onChange={(value) => handleDateChange("proxyEndTimeDelta", value)}
                                        sx={{width: "100%"}}
                                        slotProps={{
                                            textField: {
                                                value: thisRoundProxyEnd
                                            },
                                            field: {
                                                readOnly: true
                                            },
                                            desktopTrapFocus: {
                                                disableEnforceFocus: true
                                            }
                                        }}
                                    />
                                </Grid> :
                                null
                            }
                        </LocalizationProvider>
                        <Grid container size={{xs: 12}} sx={{mt: 4}}>
                            <Grid size={{xs: 12, sm: 4}} offset={{xs: 0, sm: 8}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    onClick={submitChanges}
                                    disabled={Object.values(roundTimeDelta).filter(v => v !== 0).length === 0}
                                >
                                    Submit Changes
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <TimeWaringModal
                        open={warningModalOpen}
                        timeDelta={timeDelta}
                        handleClose={handleWarningModalClose}
                        handleConfirm={handleConfirm}
                    />
                </Container>
            )
        }
    }
}
import React from "react";
import {Modal, Grid2 as Grid, Typography, Button, Divider} from "@mui/material";
import {ModalBox} from "../../components/modal-box";
import {formatDuration, intervalToDuration} from "date-fns";

interface TimeWarningModalProps {
    open: boolean;
    timeDelta: number;
    handleClose: () => void;
    handleConfirm: () => void;
}

export function TimeWaringModal({open, timeDelta, handleClose, handleConfirm}: TimeWarningModalProps) {

    const duration = formatDuration(intervalToDuration({start: 0, end: timeDelta}));

    return (
        <Modal
            open={open}
            onClose={handleClose}
            disableRestoreFocus
        >
            <ModalBox>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5">
                            Scheduling Conflict Detected
                        </Typography>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    <Grid size={{xs: 12}} sx={{mb: 2}}>
                        <Typography variant="subtitle2">
                            The date you selected conflicts with previously scheduled phases.
                        </Typography>
                        <Typography variant="subtitle2">
                            To resolve this problem, would you like to increase the start and end dates for later phases by {`${duration}`}?
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Button
                            variant="outlined"
                            fullWidth
                            color="darkPrimary"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={handleConfirm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </ModalBox>
        </Modal>
    )
}
import {useState, useCallback} from "react";
import {InstitutionSelections, OptionalScriptStepConfig} from "../../data-types";
import {DataAccess} from "../../util";


export const useOptionalStepSelection = () => {

    const [selectedRound, setSelectedRound] = useState<number>(NaN);
    const [selectedOptionalStepConfig, setSelectedOptionalStepConfig] = useState<OptionalScriptStepConfig[]>([]);
    const [selections, setSelections] = useState<InstitutionSelections>({});

    const initRoundSelection = useCallback((optionalScriptStepConfig: OptionalScriptStepConfig[]) => {
        const roundId = optionalScriptStepConfig.length > 0 ? optionalScriptStepConfig[0].roundId : NaN;
        if (!isNaN(roundId)) {
            setSelectedOptionalStepConfig(optionalScriptStepConfig);
            setSelectedRound(roundId);
            return true;
        } else {
            return false;
        }
    }, []);

    const handleInstitutionSelection = (institutionId: number | null, scriptStepId?: string) => {
        if (scriptStepId) {
            setSelections(prev => {
                return {
                    ...prev,
                    [scriptStepId]: institutionId
                }
            })
        } else {
            setSelections({});
        }
    };

    const submitSelections = async () => {
        // check if institutionSelections same length as config
        if (Object.keys(selections).length !== selectedOptionalStepConfig.length) {
            throw new Error("Must make a selection for each.");
        }
        const data = Object.entries(selections).map(([key, value]) => {
            return {
                scriptStepId: key,
                chosenInstitutionId: value
            }
        })

        await DataAccess.post(`/api/round/${selectedRound}/userStart.json`, {data: {userChoices: data}});
    }

    return {
        initRoundSelection: initRoundSelection,
        submitSelections: submitSelections,
        handleInstitutionSelection: handleInstitutionSelection,
        selectedOptionalStepConfig: selectedOptionalStepConfig,
        selections: selections
    }
}
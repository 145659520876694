import {useState, useCallback} from "react";
import {RecordingConfig, IModerationResults, FileUploadConfig} from "../../data-types";
import {AxiosProgressEvent} from "axios";
import {DataAccess} from "../../util";

export const useVideoResponseSave = () => {

    const [uploadProgress, setUploadProgress] = useState<number>(NaN);

    const saveResponse = useCallback(async (responseId: number, config: RecordingConfig | FileUploadConfig) => {
        const handleUploadProgress = (e: AxiosProgressEvent) => {
            // if for some reason the event total is undefined, then pass 1,
            // which will yield a progress > 100 and an indeterminate
            // progress bar in ui
            const total = e.total || 1;
            setUploadProgress(Math.round((e.loaded / total) * 100));
        }

        let formData = new FormData();

        if ("recordingData" in config) { // recorded
            const data = new Blob(config.recordingData, {type: config.mimeType});
            formData = new FormData();
            formData.append("file", data);
            formData.append("name", `response-${responseId}`);
            formData.append("size", (60 - config.time).toString());
            formData.append("responseId", responseId.toString());
            formData.append("contentType", config.mimeType.split(";")[0]);
        } else {  // uploaded
            formData.append("file", config.file);
            formData.append("name", `response-${responseId}`);
            formData.append("size", config.size.toString());
            formData.append("responseId", responseId.toString());
            formData.append("contentType", config.mimeType);
        }

        await DataAccess.post("/api/response/save.json", {data: formData}, {onUploadProgress: handleUploadProgress});
    }, []);

    const createTranscript = useCallback(async (responseId: number) => {
        const delay = async () => {
            return new Promise<void>((r) => setTimeout(r, 5000));
        }
        const MAX_RETRIES = 12;  // approx 1 minute
        let attemptedCount = 0;
        while (attemptedCount < MAX_RETRIES) {
            try {
                const r = await DataAccess.post(`/api/response/${responseId}/createTranscript.json`, {data: null});
                const moderationResults: IModerationResults = r.moderationResults;
                return moderationResults.results[0];
            } catch (e) {
                if (++attemptedCount >= MAX_RETRIES) {
                    throw e;
                }
            }
            await delay();
        }
    }, [])

    const submitResponses = useCallback(async (competitionId: string | undefined) => {
        await DataAccess.post(`/api/response/${competitionId}/submit.json`, {data: null});
    }, []);

    return {
        uploadProgress: uploadProgress,
        saveResponse: saveResponse,
        createTranscript: createTranscript,
        submitResponses: submitResponses
    }
}
import React, {useState} from "react";
import {Button, Container, Grid2 as Grid, List, ListItem, ListItemText, Typography, Box} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {useVideoDetail} from "../../hooks";
import {LoadingSpinner, VideoPlayer, EditTranscript, ErrorComponent} from "../../components";
import {DataFormatting} from "../../util";
import {parseISO, format} from "date-fns";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ErrorIcon from '@mui/icons-material/Error';

export function ResponseDetail() {

    const {responseId} = useParams();
    const {
        video: videoResponse,
        videoOptions,
        requestStatus,
        updateLocalTranscript
    } = useVideoDetail(responseId, false);
    const [detailsToggle, setDetailsToggle] = useState<boolean>(true);

    if (requestStatus === "loading") {
        return <LoadingSpinner />
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        if (videoResponse === null) {
            return <Container />;
        } else {
            return (
                <Container sx={{mb: 10}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Button
                                component={Link}
                                to={"/responses"}
                                variant="outlined"
                                size="small"
                                color="secondary"
                                sx={{ml: 3, mb: 3}}
                                startIcon={<KeyboardBackspaceIcon />}
                            >
                                Back to Responses
                            </Button>
                            <VideoPlayer
                                options={videoOptions}
                                height={400}
                            />
                        </Grid>
                        <Grid container size={{xs: 12, sm: 12, md: 6}}>
                            <Grid container size={{xs: 12}} justifyContent="flex-end">
                                <Grid size={{xs: 6}}>
                                    <Typography variant="h4" sx={{mb: 3}}>
                                        {detailsToggle ? "Response Details" : "Edit Transcript"}
                                    </Typography>
                                </Grid>
                                <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={videoResponse.flagged && !videoResponse.flagHandled}
                                        onClick={() => setDetailsToggle(prev => !prev)}
                                    >
                                        {detailsToggle ? "Edit Transcript" : "Back to Details"}
                                    </Button>
                                </Grid>

                            </Grid>
                            <Grid size={{xs: 12}}>
                                {detailsToggle ?
                                    <List
                                        sx={{
                                            width: "100%",
                                            bgcolor: "background.paper"
                                        }}
                                    >
                                        <ListItem>
                                            <ListItemText primary={"Video Response"} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={`Length:  ${DataFormatting.secondsToTimeString(parseInt(videoResponse.size))}`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={`Create Date:  ${format(parseISO(videoResponse.createDate + "Z"), "Pp")}`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={`Created By:  ${videoResponse.createdBy}`} />
                                        </ListItem>
                                        {videoResponse.flagged && !videoResponse.flagHandled ?
                                            <ListItem>
                                                <ListItemText primary={
                                                        <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                                            <ErrorIcon color="secondary" sx={{mr: 2}}/>
                                                            <Typography variant="subtitle2">
                                                                Your video response has content that was flagged for moderation and the transcript can not be edited.
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </ListItem> :
                                            null
                                        }
                                    </List> :
                                    <EditTranscript video={videoResponse} updateLocalTranscript={updateLocalTranscript} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )
        }
    }
}
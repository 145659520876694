import React from "react";
import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent
} from "@mui/lab";
import {IconButton, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface AddRoundTimelineItemProps {
    endConnector: boolean;
    roundNumber: number;
    competitionId: number;
    canEdit: boolean;
}

export function AddRoundTimelineItem({endConnector, roundNumber, competitionId, canEdit}: AddRoundTimelineItemProps) {

    return (
        <TimelineItem>
            <TimelineContent />
            <TimelineSeparator sx={{ml: 2, mr: 2, height: 90}}>
                {endConnector ?
                    null :
                    <TimelineConnector />
                }
                <TimelineDot color={canEdit ? "primary" : "grey"}>
                    <Tooltip title="Add a Round Here">
                        <IconButton
                            component={Link}
                            to={`/admin/events/createRound/${competitionId}/${roundNumber}`}
                            sx={{p: 0, color: "white"}}
                            disabled={!canEdit}
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </TimelineDot>
                {endConnector ?
                    <TimelineConnector /> :
                    null
                }
            </TimelineSeparator>
            <TimelineOppositeContent />
        </TimelineItem>
    )
}
import React, {useContext, useEffect, useState} from "react";
import {
    Divider,
    Paper,
    Avatar,
    Button,
    Container,
    Typography,
    Grid2 as Grid,
} from "@mui/material";
import {PasswordReset, TutorialModal, UserProfileForm} from "../../components";
import {useAvatarUpload, useDeactivateAccount, useProfileEdit} from "../../hooks";
import {formatError} from "../../util";
import {UserContext} from "../../context";
import {toast} from "react-toastify";
import {useSearchParams, useNavigate} from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {DeactivateAccountModal} from "./DeactivateAccountModal";
import EnrollTutorialVideo from "../../video/enroll_tutorial.mp4";

export function UserProfile() {
    const {user, setUser} = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const {avatarUrl, handleAvatarChange} = useAvatarUpload(`/api/avatar/createUserAvatar.json`, "user");
    const {
        editedProfileState,
        handleChange,
        submitProfileEdits,
        isModified,
        handlePhoneChange,
        handleSelectChange,
        handleDateChange,
        handleHighSchoolChange,
        editedPhoneState,
        errorHighlight,
        handleSetModified
    } = useProfileEdit(user!);
    const {handleDeactivation} = useDeactivateAccount();
    const [tutorialModalOpen, setTutorialModalOpen] = useState<boolean>(false);
    const [deactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        let src = searchParams.get("src")
        if (src === "login") {
            setTutorialModalOpen(true);
        }
    }, [searchParams]) ;

    const submit = () => {
        submitProfileEdits(false, false)
            .then(_ => {
                setUser({
                    ...editedProfileState,
                    phoneCountryCode: editedPhoneState.phoneCountryCode,
                    phone: editedPhoneState.phone.split(`+${editedPhoneState.phoneCountryCode}`)[1]
                });
                toast.success("Profile updated successfully")
            })
            .catch(e => {
                toast.error(formatError(e));
            })
    }

    const deactivate = () => {
        handleDeactivation()
            .then(_ => {
                toast.success("Account successfully archived");
                setUser(null);
            })
            .catch(e => {
                toast.error(e);
            })
    }

    const avatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleAvatarChange(e)
            .then(_ => navigate(0));
    }

    if (!user) {
        return <Container />
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container sx={{mb: 2}} alignItems="center" justifyContent="space-between">
                    <Grid>
                        <Typography variant="h4">
                            User Profile
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button
                            color="darkPrimary"
                            variant="outlined"
                            size="small"
                            onClick={() => setTutorialModalOpen(true)}
                            startIcon={<PlayCircleIcon />}
                        >
                            Tutorial
                        </Button>
                    </Grid>
                </Grid>
                <Paper component={Grid} elevation={0} container spacing={2} sx={{mt: 5, mb: 5, p: 4}} justifyContent="align" alignItems="center">
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            General
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Typography variant="h6">
                            {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography variant="body1">
                            {user.username}
                        </Typography>
                        <Typography variant="body1">
                            {user.email}
                        </Typography>
                    </Grid>
                    <Grid container size={{xs: 12, sm: 6}} direction="column" justifyContent="align" alignItems="center">
                        <Avatar
                            src={avatarUrl !== "" ? avatarUrl : user.avatar ? `data:image/jpeg;base64,${user.avatar}` : ""}
                            sx={{
                                height: 125,
                                width: 125,
                                mb: 3
                            }}
                            imgProps={{
                                alt: "user avatar"
                            }}
                        />
                        <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            size="small"
                        >
                            Click to Change your Avatar
                            <input
                                style={{display: 'none'}}
                                accept="image/*"
                                type="file"
                                id="user-avatar"
                                onChange={avatarChange}
                            />
                        </Button>
                    </Grid>
                </Paper>
                <Paper elevation={0} sx={{my: 5, p: 4}}>
                    <UserProfileForm
                        currentProfileState={user}
                        editedProfileState={editedProfileState}
                        handleChange={handleChange}
                        handlePhoneChange={handlePhoneChange}
                        handleDateChange={handleDateChange}
                        handleHighSchoolChange={handleHighSchoolChange}
                        handleSelectChange={handleSelectChange}
                        isModified={isModified}
                        residentOnly={false}
                        requiredOnly={false}
                        birthdayOnly={false}
                        submit={submit}
                        errorHighlight={errorHighlight}
                        editedPhoneState={editedPhoneState}
                        handleSetModified={handleSetModified}
                    />
                </Paper>
                <Paper elevation={0} sx={{mt: 5, mb: 5, p: 4}}>
                    <PasswordReset />
                </Paper>
                <Paper elevation={0} sx={{mt: 5, mb: 5, p: 4}}>
                    <Grid container>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h4">
                                Deactivate Your Account
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Divider sx={{my: 2}} />
                        </Grid>
                        <Grid container spacing={2} size={{xs: 12}} justifyContent="center" alignItems="center">
                            <Grid size={{xs: 12, sm: 12, md: 6}}>
                                <Typography variant="subtitle2">
                                    Deactivating your account will log you out and lock your account from further logins.  Furthermore, deactivating your account will disqualify you from any current events that you are enrolled in.
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 12, md: 6}} sx={{textAlign: "center"}}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<ErrorIcon />}
                                    onClick={() => setDeactivateModalOpen(true)}
                                >
                                    Deactivate Account
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <TutorialModal
                    open={tutorialModalOpen}
                    setOpen={setTutorialModalOpen}
                    video={EnrollTutorialVideo}
                    title="Welcome to FocusRing!"
                >
                    <Typography variant="subtitle2" sx={{mb: 2}}>
                        Now that you’ve registered, please take these few steps to get started:
                    </Typography>
                    <Typography variant="subtitle2">
                        1). Watch this brief tutorial video on how to enroll in a FocusRing event.
                    </Typography>
                    <Typography variant="subtitle2">
                        2). After the video, click the “Close” button.
                    </Typography>
                    <Typography variant="subtitle2">
                        3). Be sure to complete all required fields in the profile that appears after you click "Close".
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontWeight: "bold"}}>
                        Good luck!
                    </Typography>
                </TutorialModal>
                <DeactivateAccountModal
                    open={deactivateModalOpen}
                    setOpen={setDeactivateModalOpen}
                    action={deactivate}
                />
            </Container>
        )
    }
}

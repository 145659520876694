import {useState, useCallback} from "react";
import {DataAccess} from "../../util";
import {HighSchool, RequestStatus} from "../../data-types";

export const useHighSchoolSearch = () => {
    const [highSchoolSearchResults, setHighSchoolSearchResults] = useState<HighSchool[]>([]);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("complete");

    const searchHighSchools = useCallback((city: string, state: string) => {

        const controller = new AbortController();

        const getHighSchools = async () => {
            const data = {data: {highSchoolState: state, highSchoolCity: city}};
            return await DataAccess.post("/api/user/getHighSchool.json", data, {signal: controller.signal});
        }

        getHighSchools()
            .then(r => {
                setHighSchoolSearchResults(r.highSchoolSearchResults ?? []);
                setRequestStatus("complete");
            })
            .catch(e => {
                setRequestStatus("error");
            })
    }, []);

    return {
        requestStatus: requestStatus,
        highSchoolSearchResults: highSchoolSearchResults,
        searchHighSchools: searchHighSchools
    }
}
import {EditedPhoneState, UserProfileState} from "../../data-types";

export const DataValidation = {

    zipCodeRegex: /^\d{5}(?:[-\s]\d{4})?$/,

    emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,

    webAddressRegex: /^https?:\/\/[^\s/$.?#].[^\s]*$/,

    // eslint-disable-next-line
    passwordRegex: /^(?=.*[A-Z])(?=.*[!^%()_\-+={}\[\]<>,./?:;"'@#$&*])(?=.*[0-9])(?=.*[a-z]).{12,}$/,

    gpaRegex: /^(?:[0-4]\.\d{1,2}|5\.0{1,2})$/,

    isUserProfileComplete: function(user: UserProfileState, editedPhone?: EditedPhoneState): boolean {
        if (user.usResident === null) {
            return false;
        }

        const phoneValid = (editedPhone) ?
            (editedPhone.phone !== null && editedPhone.phoneCountryCode !== null) :
            (user.phone !== null && user.phoneCountryCode !== null);

        const nonResidentRequiredFields =
            user.regionOrCountry !== null &&
            user.dateOfBirth !== null &&
            phoneValid;

        if (!user.usResident) {
            // if user is not usResident, then only demographics are required
            return nonResidentRequiredFields;
        } else {
            // if user is resident, all other fields required
            return nonResidentRequiredFields &&
                user.highSchoolCity !== null &&
                user.highSchoolState !== null &&
                user.highSchoolName !== null;
        }
    },

    isValidGpa:  function(gpa: string | number | null): boolean {
        if (gpa === null) {
            return false;
        }
        return this.gpaRegex.test(gpa.toString());
    },

    getUserProfileIncompleteFields: function(user: UserProfileState, phoneState: EditedPhoneState): string[] {
        if (user.usResident === null) {
            return ["usResident"];
        } else {
            const nonUsResidentFields = ["dateOfBirth", "regionOrCountry"];
            const usResidentFields = ["highSchoolCity", "highSchoolState", "highSchoolName"];
            const [_, currentPhoneState] = phoneState.phone.split(`+${phoneState.phoneCountryCode}`);

            if (!user.usResident) {
                // only check demo fields
                const incompleteFields = nonUsResidentFields.filter(c => user[c as keyof UserProfileState] === null);
                return currentPhoneState ? incompleteFields : [...incompleteFields, "phone"];
            } else {
                const incompleteFields = [...nonUsResidentFields, ...usResidentFields].filter(c => user[c as keyof UserProfileState] === null);
                return currentPhoneState ? incompleteFields : [...incompleteFields, "phone"];
            }
        }
    },

    isValidDate: function(date: Date): boolean {
        return !isNaN(date.getTime());
    },

    isValidZipCode: function(zipCode: string): boolean {
        return this.zipCodeRegex.test(zipCode);
    },

    isValidEmailAddress:  function(email: string): boolean {
        return this.emailRegex.test(email);
    },

    isValidWebAddress: function(webAddress: string): boolean {
        return this.webAddressRegex.test(webAddress);
    },

    isValidPassword: function(s: string): boolean {
        return this.passwordRegex.test(s);
    }
}
import React, {useState} from "react";
import {Divider, Grid2 as Grid, Button, Tooltip} from "@mui/material";
import {EditTranscriptProps} from "../../data-types";
import {useEditTranscript} from "../../hooks";
import {TranscriptEntry} from "./TranscriptEntry";
import {useNavigate} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {AddEntryDialog} from "./AddEntryDialog";
import {DataFormatting, formatError} from "../../util";
import {toast} from "react-toastify";

export function EditTranscript({video, updateLocalTranscript}: EditTranscriptProps) {

    const {
        entries,
        handleChange,
        handleDeleteEntry,
        handleAddEntry,
        isModified,
        submit} = useEditTranscript(video.transcript);
    const navigate = useNavigate();
    const [addEntryOpen, setAddEntryOpen] = useState<boolean>(false);

    const handleClose = () => {
        setAddEntryOpen(false);
    }

    const submitChanges = () => {
        submit(video)
            .then(_ => {
                toast.success("Transcript updated successfully");
                navigate(0)
            })
            .catch(e => {
                const formatted = formatError(e);
                toast.error(formatted);
                console.log(formatted);
            })
    }


    return (
        <Grid container sx={{bgcolor: "background.paper", p: 3, maxHeight: 450, overflowY: "auto"}}>
            <Grid container size={{xs: 12}} sx={{ mb: 2}}>
                <Grid size={{xs: 6}}>
                    <Tooltip title="Insert a new entry into the transcript">
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => setAddEntryOpen(true)}
                        >
                            Add Entry
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                    <Tooltip title="Click to see your transcript changes reflected in the video">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<RefreshIcon />}
                            onClick={() => updateLocalTranscript(DataFormatting.serializeToVtt(entries))}
                        >
                            Sync with Video
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            {entries.map((c, i) =>
                <TranscriptEntry
                    key={i}
                    entry={c}
                    handleChange={handleChange}
                    handleDeleteEntry={handleDeleteEntry}
                />
            )}
            <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                <Divider sx={{mt: 3, mb: 3}} />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={submitChanges}
                    disabled={!isModified}
                >
                    Submit Edits
                </Button>
            </Grid>
            <AddEntryDialog open={addEntryOpen} handleClose={handleClose} handleAddEntry={handleAddEntry} />
        </Grid>
    )
}
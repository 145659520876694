import React from "react";
import {Modal, Grid2 as Grid, LinearProgress, Typography, Button} from "@mui/material";
import {ModalBox} from "../modal-box";
import {Link} from "react-router-dom";
import {TypographyInlineLinks} from "../typography-inline-links";
import {RequestStatus, TranscriptRequestStatus, ModerationResult} from "../../data-types";

interface VideoUploadingModalProps {
    open: boolean;
    handleClose: () => void;
    uploadStatus: RequestStatus;
    videoUploadProgress: number;
    transcriptStatus: TranscriptRequestStatus;
    moderationResult?: ModerationResult;
    retryTranscript: () => void;
    link: string;
}

export function VideoUploadingModal({open, uploadStatus, videoUploadProgress, handleClose, transcriptStatus, link, retryTranscript, moderationResult}: VideoUploadingModalProps) {

    const selfHarmResources: JSX.Element = <>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            Hey there,
        </Typography>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            We listened to your video and found that you used some words and phrases that are oftentimes used by people in crisis.
        </Typography>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            If you're going through something difficult, then we'd like to help.
        </Typography>
        <TypographyInlineLinks
            text={"- Call or text 988, or visit https://988lifeline.org.  You'll be connected to somebody who will listen and can help you."}
            variant="subtitle2"
        />
        <Typography variant="subtitle2" sx={{my: 1}}>
            - Take a break.  Putting yourself out there for others to see can be extremely difficult for many of us.
        </Typography>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            If you think this judgement was made in error, you can close this modal.  Feel free to re-record your video when or if you're ready.
        </Typography>
    </>;

    const genericModerationResponse: JSX.Element = <>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            Hello,
        </Typography>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            Our moderation systems detected some egregious content originating from your video.
        </Typography>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            While no content moderation system is perfect, we will flag this video for further review by event administrators who may choose to reduce your score or remove you from the event entirely.
        </Typography>
        <Typography variant="subtitle2" sx={{mb: 1}}>
            You may re-record your video and resubmit to remove this flag from your account.
        </Typography>
    </>;

    const moderationContent = (moderationResult?: ModerationResult): JSX.Element => {
        if (moderationResult) {
            const selfHarmTypes: string[] = ["self-harm", "self-harm/intent", "self-harm/instructions"];
            const flaggedTypes = Object.entries(moderationResult.categories).filter(([, v]) => v).map(([k]) => k);
            if (selfHarmTypes.some(v => flaggedTypes.includes(v))) {
                return selfHarmResources;
            } else if (flaggedTypes.length > 0) {
                return genericModerationResponse;
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    }

    return (
        <Modal
            open={open}
        >
            <ModalBox>
                {(uploadStatus === "complete") ?
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Video upload complete
                            </Typography>
                        </Grid>
                        {(transcriptStatus === "complete") ?
                            <>
                                <Grid size={{xs: 12}}>
                                    <Typography variant="subtitle2">
                                        Would you like to review this video's transcript?
                                    </Typography>
                                </Grid>
                                <Grid size={{xs: 12, sm: 6}}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                                <Grid size={{xs: 12, sm: 6}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        component={Link}
                                        to={link}
                                    >
                                        Review Transcript
                                    </Button>
                                </Grid>
                            </> :
                            (transcriptStatus === "loading") ?
                                <>
                                    <Grid size={{xs: 12}}>
                                        <Typography variant="subtitle2">
                                            Creating transcript...
                                        </Typography>
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <LinearProgress
                                            color="primary"
                                            variant="indeterminate"
                                        />
                                    </Grid>
                                </> :
                                (transcriptStatus === "moderation") ?
                                    <>
                                        <Grid size={{xs: 12}}>
                                            {moderationContent(moderationResult)}
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <Button
                                                component={Link}
                                                color="primary"
                                                fullWidth
                                                variant="outlined"
                                                to={link}
                                            >
                                                Review Transcript
                                            </Button>
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={handleClose}
                                            >
                                                Retry
                                            </Button>
                                        </Grid>
                                    </> :
                                <>
                                    <Grid size={{xs: 12}}>
                                        <Typography variant="subtitle2">
                                            An error occurred when creating the transcript.
                                        </Typography>
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={retryTranscript}
                                        >
                                            Retry Transcript
                                        </Button>
                                    </Grid>
                                </>
                        }
                    </Grid> :
                    (uploadStatus === "loading") ?
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12}}>
                                <LinearProgress
                                    value={isNaN(videoUploadProgress) ? 0 : videoUploadProgress}
                                    variant={(isNaN(videoUploadProgress) || videoUploadProgress === 100) ? "indeterminate" : "determinate"}
                                    color="primary"
                                />
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <Typography variant="subtitle2">
                                    {(isNaN(videoUploadProgress) || videoUploadProgress === 100) ? "Processing..." : `${videoUploadProgress}% Uploaded`}
                                </Typography>
                            </Grid>
                        </Grid> :
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12}}>
                                <Typography variant="subtitle2">
                                    There was an error in submitting your video.
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 6}}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                }
            </ModalBox>
        </Modal>
    )
}